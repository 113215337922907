.login-header-new {
  background: #ffffff;
  padding: 2px 5px;
  color: #fff;
  /* position: fixed; */
  z-index: 99;
  width: 100%; top: 0;
}

.login-header-new a {
  color: #fff;
  text-decoration: none;
  margin-right: 12px;
  margin-left: 20px;
}

.main {
  padding: 3%;
  /* height: calc(100vh - 172px); */
  overflow-y: auto;
  /* margin-bottom: 20px; */
  background: #ffffff;
}
.login-reg-wrapper {
  display: flex;     justify-content: space-between;
  align-items: center; height: 45px;
}

.login-reg-wrapper figure {
  padding: 0px;
  margin: 0;
  margin-right: 0px;
  display: flex;
  align-items: center;
}

.login-reg-wrapper figure img {
  /* filter: brightness(0) invert(1); */
}
.login-reg-wrapper .language-select-div {
  width: auto;
  vertical-align: middle;
  text-align: center;
  color: #000;
  line-height: 16px;
  font-size: 14px;
}
.login-reg-wrapper .language-select-div img {
  margin: auto!important;
  height: 20px;
}

.footer-new {
  position: fixed;
  z-index: 104;
  bottom: 0;
  width: 100%;
  height: auto;
  /* border-top: 1.266667vw solid #333333; */
  background: #ebebeb;

}
.footer-payment-box span{
color: #ffffff; font-size: 3.2vw;
}
.payment-sec .payment-box {
  width: 70px;
  padding: 6px 8px !important;
  text-align: left;
  display: flex !important;
  align-items: center !important;
  justify-content: start !important;
  flex-direction: column;
  background: #333;
  min-width: 70px!important;
  /* justify-content: space-between; */
  height: 73px;
  margin-right: 10px;
  border-radius: 4px;
}
.home-tabing-slider .payment-sec .active {
  background: #ffce01!important;
  
}
.home-tabing-slider .payment-sec .payment-box.active figure img{
 
  filter: invert(1);
}
.payment-sec .active span{
  color: #000!important;
}
.payment-sec {
  display: flex;
  /* flex-wrap: wrap; */
  margin: 0px 0px 13px;
  overflow: auto;
  padding-bottom: 8px;
}

.payment-sec .payment-box span {
  font-size: 10px;
  color: #fff;
  font-weight: 600;
  text-align: left;
  width: 100%;
}
.payment-sec .payment-box figure{
  margin: 0;
  }
.heading-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-bottom: 1px solid #00000021; */
  padding-bottom: 11px;
  margin: -10px -9px 0px;
  padding: 10px 10px 0px;
}

.heading-block h3 {
  font-size: 13px;
  font-weight: 500;
  color: #000000d9;
  font-weight: 900;
  margin-bottom: 0;
}

.heading-block a {
  text-decoration: none;
  font-size: 13px;
  color: #14805ee8;
}

.slick-slide img {
  display: block;
  width: 100%;
}
.common-slider {
  padding: 13px;
  /* background: #333; */
  margin: 0 -13px 0;
}
.banner-slider.border-0.p-0 {
  margin-bottom: 10px;
}
.common-slider h6 {
  font-size: 4vw;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  color:#000;
}
.common-slider h2 {
  font-size: 12px;
}
.main-slider {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.common-slider span {
  font-size: 12px;
  display: block;
  margin-top: 4px;
}

.common-slider figcaption {
  padding: 7px 9px;
  /* position: absolute; */
  bottom: 0;
  background: #333;
  left: 0;
  right: 0;
  /* border-top: 1px solid #0000002b; */
}
.common-slider .slick-slide > div {
  margin: 10px 5px 0px;
}

.border-0 {
  border: none;
}

/*

.slick-slide {
  margin: 23px 9px 0px;
} */

.footer-new ul {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding:  0;
}
.footer-new ul li {
  width:50%;
  text-align: center;
  list-style: none;
  padding: 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
    line-height: 60px;
}

.footer-new ul li a {
  text-decoration: none;
  color: #000;
  font-weight: 700;
  font-family: "Inter";
  display: flex;
  width: 100%;
  padding: 10px 0px;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  text-align: left;
}

.login-footer{background: #000;}
.login-footer.footer-new ul li {
  width: 50%;
  text-align: center;
  list-style: none;
  padding: 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  line-height: 10px;
}
.login-footer .footer-payment-box figure img {
  width: 22px;
  height: 22px;
}
.login-footer  .footer-payment-box span {
  color: #ffffff;
  font-size: 11px;
}







.footer-new ul li .language-select-div {
  text-decoration: none;
  color: #000;
  font-weight: 700;
  font-family: "Inter";
  display: flex;
  /* width: 100%; */
  padding: 4px 0px;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  text-align: left;
}
.footer-new ul li.language-select {
  background-color: #d7e3f0;
}
.footer-new ul li.register-button {
  background-color: #ffdf1a;
}
.footer-new ul li.login-button {
  background-color: #0066d1;
}
.footer-new ul li.login-button a {
  color: #fff;
}

.footer-new ul li a {
  text-decoration: none;
}

.slider-items {
  border: none;
  border-radius: 0px;
  overflow: hidden;
  position: relative;
}

.banner-slider .slider-items {
  border: none;
}

.explore-slider .slider-items {
  overflow: visible;
}

.explore-slider .slick-slide img {
  display: block;
  max-width: 37px;
  margin: 0 auto;
}

.payment-sec .payment-box img {
  /* display: block; */
  width: 35px;
  height: 39px;
  /* margin: 0 auto 1.8666666667vw; */
  transition: all .3s;
  border-radius: 0;
  opacity: 1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  margin-bottom: 4px;
  margin-top: 5px;
}

.sportsbook-filter {
  filter: brightness(4) contrast(63.5);
}

.banner-slider .slick-slide > div {
  margin: 0px 0px 0px;
}
.banner-slider .slick-slide > div:first-child {
  margin-left: 0px;
}

.pay {
  background: #ebebeb;
  margin: 0px -13px;
  padding: 28px 16px;
  margin-top: 15px;
}
.pay h2 {
  color: #3a3a3a;
  font-size: 14px;
  font-weight: 600;
}
.pay ul {
  display: flex;
  padding: 0;
  /* flex-wrap: wrap; */
}
.pay ul li img {
  width: 100%;
  max-height: 32px;
  /* filter: brightness(0) invert(1); */
  /* filter: brightness(0.3) invert(1); */
}
.pay .gaming-lic ul li img
{
  filter: none;
}
.pay ul li {
  padding-right: 17px;
  list-style-type: none;
}

.payment-inner {
  display: flex;
  margin-top: 15px;
  /* border-bottom: 0.2666666667vw solid #333333; */
}

.payment-inner .payment-row {
  width: 100%;
}
.payment-row.social-row ul {
  flex-wrap: wrap;
}
.payment-row.social-row ul li {
  margin-bottom: 12px;
}

.language-sec {
  display: flex;
  flex-wrap: wrap;
  padding: 22px 0px 0;
}

.language-sec h6 {
  color: #fff;
  padding-right: 30px;
  margin-bottom: 0px;
  /* width: calc(33.33% - 10px); */
  /* text-align: center; */
  font-size: 12px;
  display: flex;
  position: relative;
  align-items: center;
}

/* .language-sec h6::before {
  position: absolute;
  left: -7px;
  content: "";
  background-color: #fff;
  width: 1px;
  height: 16px;
  top: 50%;
  transform: translateY(-50%);
} */
.language-sec h6 a {
  color: #3a3a3a;
  text-decoration: none;
  font-weight: 400;
  font-size: .75rem;
  text-decoration: underline;
  font-family: 'Heebo-Medium', sans-serif !important;
}
.login-banner .slider-items {
  border-radius: 0px;
}

.login-banner .slick-dots {
  bottom: 5px;
  padding: 0;
  background: #0003;
}

.login-banner .slick-dots li {
  padding: 0;
  cursor: pointer;
}

.login-banner .slick-dots li button:before {
  font-size: 13px;
  opacity: 1;
  color: #000;
}

.login-banner .slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #14805e;
}

.login-welcome {
  padding: 38px 26px;
  text-align: center;
}



.login-welcome {
  padding: 38px 26px;
  text-align: center;
}

.login-welcome h2 {
  font-size: 27px;
  line-height: 39px;
  font-family: "Alkatra";
  color: #fff;
}

.login-reg-btn {
  margin-top: 51px;
}

.login-reg-btn a {
  display: inline-block;
  padding: 13px 28px;
  text-decoration: none;
  border-radius: 25px;
  margin-bottom: 8px;
  font-size: 16px;
}

.login-reg-btn a.active {
  background: #14805e;
  color: #fff;
}

.login-reg-btn a.active:hover {
  background: #fff;
  color: #14805e;
  border: 1px solid #14805e;
}
/*
.all-offer-link img {
  max-width: 31px;
  filter: brightness(0) invert(1);
} */

/* .all-offer-link {
  width: 20px;
  height: 20px;
  background: #14805e;
  padding: 10px;
  display: block;
  border-radius: 100%;
} */

.menulist-sidebar {
  background: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  position: fixed;
  top: 0;
  bottom: 0;
  right: -100%;
  z-index: 23;
  width: 287px;
  padding: 20px 0px;
  overflow-y: auto;
  transition: 0.5s;
}
.menulist-sidebar ul {
  padding: 0;
  margin-bottom: 0;
}

.menulist-sidebar ul li {
  list-style: none;
  border-bottom: 1px solid #00000017;
}

.menulist-sidebar ul li a {
  text-decoration: none;
  display: block;
  padding: 12px 15px;
  color: #000;
  display: flex;
  align-items: center;
}

.menulist-sidebar h2 {
  font-size: 19px;
  color: #14805e;
}
.menulist-sidebar .menu-header {
  padding: 0px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.banner-menu-listing {
  /* margin: 0px -20px; */
  filter: brightness(0.9);
}
.menu-listing-bottom {
  padding: 0px 13px;
}
.menu-listing-bottom p {
  font-size: 15px;
  line-height: 22px;
  font-family: "Lato";
}
.menulist-sidebar .login-reg-btn {
  margin-top: 34px;
}
.menulist-sidebar ul li:last-child {
  border: none;
}

.menu-listing-bottom p a {
  color: #14805e;
  text-decoration: none;
  font-weight: 800;
}
.menulist-sidebar .login-reg-btn a {
  border-radius: 0px;
}

.menu-header .button {
  text-decoration: none;
  /* color: #14805e; */
  padding: 8px 19px;
  display: inline-block;
  border: 1px solid #14805e;
  border-radius: 25px;
  /* margin-top: 21px; */
  font-size: 14px;
  background: #000;
  color: #fff;
  border-color: #fff;
}

.menulist-sidebar ul li a > div {
  margin-right: 14px;
}

.menulist-sidebar ul li a > div img {
  max-width: 22px;
}

.menulist-sidebar.active {
  right: 0;
}

.registration-form, .login-form {
  /* position: fixed; */
  /* top: 0; */
  /* bottom: -100%; */
  background:#EBEBEB;
  /* padding: 20px; */
  right: 0;
  left: 0;
  transition: 0.5s;
}
.registration-form .main-banner{
background: none;
}
.reg-data h2 {
  font-size: 23px;
  font-weight: 100;
  line-height: 31px;
  color: #fff;
}
.reg-data h2 strong {
  font-size: 20px;
}
.registration-form label, .login-form label {
  display: block;
  margin-bottom: 8px;
  color: #000c;
  /* font-size: 3.4666666667vw; */
  margin-right: 20px;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
}
.registration-form label .red--text, .login-form label .red--text {
  color: #f44336!important;
  caret-color: #f44336!important;
}
.reg-data {
  margin-bottom: 16px;
  padding: 42px 32px;
  height: 80vh;
}

.registration-form .form-control, .login-form .form-control {
  padding: 8px!important;
  margin-left: 0px;
  border: none;
  width: 100%;
  color: #000;
  font-size: 15px;
  background: transparent;
  border: none;
  font-size: 3.4666666667vw;
  width: 100%;
  border: #3a3a3a thin solid;
  border-radius: 10px;
}

.registration-form form span, .login-form form span {
  display: block;
  overflow: hidden;
  color: #fff;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 30px;
  font-weight: 500;
}

.login-form .invalid-feedback{
  margin: 0 0 20px !important;
  color:#dc3545;
}
.registration-form .form-group , .login-form .form-group {
  border-bottom: 1px solid #00000026;
  padding-bottom: 8px;
}

.registration-form p , .login-form p{
 
  color: #000c;
  font-size: 3.2vw;
  line-height: 1.2;
  text-align: center;
}

.registration-form p a , .login-form p a{
  color: #14805e;
  font-weight: 600;
}
.term-condtion {
  margin-top: 29px;
}
.back-header p {
  color: #ffce01;
  font-size: 18px;
  text-align: center;
  margin: 0 !important;
  font-weight: 500;
}

.reg-data .reg-logo {
  width: 53.3333333333vw;
  height: 12vw;
  margin: 0 auto 26px;
}

.reg-data .reg-logo img {
  width: 100%;
  height: 100%;
}
.login-data-d {
  /* background: #fff; */
  /* display: block; */
  /* padding-left: 20px; */
  /* border-radius: 0.8vw 0.8vw 0vw 0vw; */
  /* height: 60px; */
  align-items: center;
  /* margin-bottom: 0px !important; */
  /* border: #ccc thin solid; */
  /* margin-top: -1px; */
  margin-bottom: 20px;
}
.back-header {
  display: flex;
  justify-content: space-between;
  justify-content: center;
  height: 48px;
  /* border-bottom: 0.2666666667vw solid rgba(153,153,153,.3); */
  /* background: #ffffff; */
  align-items: center;
  position: relative;
  background-color: #000;
  color: #ffce01;
}
.forgot-back-header{
  display: flex;
  justify-content: space-between;
 justify-content: center;
  height: 13.3333333333vw;
  border-bottom: 0.2666666667vw solid rgba(153,153,153,.3);
  background: #005dac;
  align-items: center;
  position: relative;
}
.forgot-back-header p{
  color: #fff;
    font-size: 18px;
    text-align: center;
    margin: 0 !important;
}
.forgot-back-header img{
  filter: invert(1);
}
.left-arrow{
  position: absolute;
    left: 16px 
}


.back-header span,.forgot-back-header  span {
  display: block;
  transform: rotate(180deg);
}

.registration-form .login-form {
  position: fixed;
  top: 100%;
  background: rgb(17, 17, 17);
  padding: 20px;
  right: 0;
  left: 0;
  transition: 0.5s;
  height: 100%;
}
.registration-form.active , .login-form.active{
  top: 0px;
}
.login-info-box {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-end;
  margin: 0 2.6666666667vw;
}
.forgetpassword-buttn {
  font-size: 3.4666666667vw;
}
.forgetpassword-buttn a {
  /* padding: 3px; */
  /* border: 1px solid #005dac; */
  /* border-radius: .8vw; */
  color: #acacac;
  /* text-decoration: none; */
  text-transform: capitalize;
  font-weight: 500;
}
.register-content .tab-btn-page {
  position: fixed;
}
.tab-btn-page {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  width: 100%;
  height: 13.3333333333vw;
  transition: all .3s;
  background: #005dac;
  z-index: 3;

    top: 13.3333333333vw;
    box-shadow: 0 0.2666666667vw 0.8vw #0000004d;
    z-index: 4;
}
.tab-btn-page .active {
  color: #ffe800!important;
  border: none;
  border-bottom: 3px solid #ffe800;
}
.tab-btn-page .line {
  display: block;
  position: absolute;
  width: 33.3333333333%;
  height: .8vw;
  left: 0;
  bottom: 0;
  background: #ffe800;
  border-radius: .8vw;
  transition: all .3s;
}
.tab-btn-page .btn {
  position: relative;
  width: inherit!important;
  padding: 4vw 0;
  text-align: center;
  transition: all .3s;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  color: #fff;
}
.overlay {
  background: #0000008c;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  top: z;
  top: 0;
  z-index: 2;
  position: absolute;
  z-index: 20;
}

.registration-form form .login-form form {
  margin-top: 29px;
}

input[type="text"]:focus {
  outline: none !important;
}

/* .slider-items.slider-items-offer figure {
  height: 97px;
  display: flex;
  align-items: center;
  justify-content: center;
} */

.footer-new ul li.language-select img {
  max-width: 25px;
  /* margin-right: 14px; */
}

.footer-bottom h6 {
  margin-bottom: 1.3333333333vw;
  color: #0088da;
  font-size: 3.4666666667vw;
  font-weight: 700;
}
.footer-bottom p {
  margin-top: 35px;
  font-size: 16px;
  margin-bottom: 40px;
  color: rgba(0,0,0,.87);
  font-weight: 600;
}



.next-btn button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  padding: 13px 20px;
  background: #14805e;
  color: #fff;
}

.next-btn {
  margin: 0px -20px;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.registration-form form .next-btn span {
  font-weight: 600;
  font-family: "Inter";
  font-size: 16px;
}

.next-btn button:disabled {
  background: #00000057;
}

.next-btn button:disabled span {
  color: #fff;
}
.footer-new ul li.language-select img {
  max-width: 25px;
  margin-right: 14px;
}
.next-btn img {
  max-width: 24px;
  filter: brightness(0) invert(1);
}

.theme-btn-new {
  background: #14805e;
  color: #fff;
  border: none;
  padding: 13px 15px;
  font-size: 16px;
  transition: 0.5s;
  border: 1px solid #14805e;
  font-size: 14px;
  font-weight: 700;
}

.theme-btn-new:hover {
  background: transparent;
  color: #14805e;
}

.otp-verfication {
  padding: 0px 23px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

input.form-control {
  padding: 10px 7px;
}

.otp-verfication .form-group {
  display: flex;
}

.otp-verfication .form-group input[type="text"] {
  width: calc(25% - 10px);
  margin: 32px 5px;
  border-radius: 7px;
  padding: 17px 12px;
  border: 1px solid #ced4da;
  text-align: center;
  font-size: 19px;
}

.otp-verfication h4 {
  font-size: 19px;
  margin-bottom: 11px;
}

.otp-sent {
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  /* font-family: 'Inter'; */
}

.otp-sent strong {
  color: #fff;
}

.otp-verfication .resent-otp {
  margin-bottom: 28px;
  font-size: 14px;
}

.resent-otp span {
  text-decoration: none;
  color: #14805e;
  font-weight: 600;
  /* font-size: 15px; */
}

.otp-verfication figure img {
  width: 100%;
  max-width: 182px;
}
.otp-verfication figure {
  margin-bottom: 71px;
}

.pay ul li a {
  display: block;
}
.react-tel-input .form-control {
  border: none !important;
  font-size: 15px !important;
  padding-left: 48px!important;
}
.text-danger {
  color: red;
  font-size: 14px;
}

.form-control:focus {
  /* outline: none !important;
  border: none !important;
  box-shadow: none !important; */
  border-color: #fddd04 !important;
    box-shadow: none !important;
}
.btn-remove {
  border: none;
  padding: 10px 0;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  width: 100%;
  margin-top: 5px;
  border-radius: 20px;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
.main-slider-div {
  display: block;
}
.main-slider-inner-div {
  padding: 0 0px;
}

.main-slider-inner-div h5,.common-slider h5 {
  margin-bottom:10px;
  color: rgba(0,0,0,.87);
   font-size: 4vw;
   font-family: 'Heebo-Bold', sans-serif !important;
    letter-spacing: 0px !important;
    font-weight: 700!important;
}

/* .main-slider-inner-div h5:before,.common-slider h5:before {
  content: "";
  display: inline-block;
  width: 1.0666666667vw;
  height: 4vw;
  margin-right: 1.3333333333vw;
  background-color: #14805e;
} */

.main-slider-inner-div ul {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  padding: 0 !important;
  gap: 0.8rem 0.8rem;
  justify-content: start;
}
.main-slider-inner-div ul li {
  position: relative;
  width: 31%;
  padding: 0;
  /* min-width: 30vw; */
  /* max-width: 30vw; */
  /* height: auto!important; */
  flex-direction: column;
  padding: unset;
  display: inline-block;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 120px;
  border-radius: 20px;
}
.main-slider-inner-div ul li div {
  padding: 2.6666666667vw 0;
}
.main-slider-inner-div ul li div span {
  height: 90px;
  display: flex; margin-bottom: 0px;
  align-items: center;
}
.main-slider-inner-div ul li div img {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; border-radius: 10px;
}
.main-slider-inner-div ul li div p {
  color: #fff;
  margin: 0px;
  font-size: 3.4666666667vw;
  text-align: center;
}
.casino-main {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 0 1.3333333333vw;
}

.casino-card {
  position: relative;
  width: 46vw;
  height: auto;
  margin: 0 1.3333333333vw 2.6666666667vw;
  overflow: hidden;
  border-radius: 0.8vw;
}
.footer-payment-box figure img {
  width: 25px;
  height: 25px;
}
.payment-box.footer-payment-box figure {
  margin:0;
}
.footer-payment-box span {
  font-weight: 500;
}

.login-header-new + .main-banner 
{
  /* margin-top:54px */
}

.main-banner {
  height: auto;
  margin-top: 0;
  margin-bottom: 0;
  /* padding-bottom: 3px; */
  background: #ffffff;
}
.slider-items-banner {
  /* padding: 0 5px; */
  height: 100%;
}
.slider-items-banner figure img {
  /* border-radius: 7px; */
}
.main-banner .slick-dots li {
}

/* .slick-list {
  overflow: ${props => (props.overflow ? "visible" : "hidden")};
} */
/* Slider */
.main-banner .slick-slider {
  margin-bottom: 0px;

  position: relative;

  display: block;
  box-sizing: border-box;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.main-banner .slick-list {
  position: relative;

  display: block;
  /* overflow: hidden; */

  margin: 0;
  padding: 0;
}
.main-banner .slick-list:focus {
  outline: none;
}
.main-banner .slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.main-banner .slick-slider .slick-track,
.main-banner .slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.main-banner .slick-track {
  position: relative;
  top: 0;
  left: 0;

  display: block;
  margin-left: auto;
  margin-right: auto;
}
.main-banner .slick-track:before,
.main-banner .slick-track:after {
  display: table;

  content: "";
}
.main-banner .slick-track:after {
  clear: both;
}
.main-banner .slick-loading .slick-track {
  visibility: hidden;
}

.main-banner .slick-slide {
  display: none;
  float: left;

  height: 100%;
  min-height: 1px;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.main-banner .slick-slide img {
  display: block;
  width: auto!important;
  height: auto!important;
}
.main-banner .slick-slide.slick-loading img {
  display: none;
}
.main-banner .slick-slide.dragging img {
  pointer-events: none;
}
.main-banner .slick-initialized .slick-slide {
  display: block;
}
.main-banner .slick-loading .slick-slide {
  visibility: hidden;
}
.main-banner .slick-vertical .slick-slide {
  display: block;

  height: auto;

  border: 1px solid transparent;
}
.main-banner .slick-arrow.slick-hidden {
  display: none;
}
/* Arrows */
.main-banner .slick-prev,
.main-banner .slick-next {
  font-size: 0;
  line-height: 0;

  position: absolute;
  top: 50%;

  display: block;

  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);

  cursor: pointer;

  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}
.main-banner .slick-prev:hover,
.main-banner .slick-prev:focus,
.main-banner .slick-next:hover,
.main-banner .slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}
.main-banner .slick-prev:hover:before,
.main-banner .slick-prev:focus:before,
.main-banner .slick-next:hover:before,
.main-banner .slick-next:focus:before {
  opacity: 1;
}
.main-banner .slick-prev.slick-disabled:before,
.main-banner .slick-next.slick-disabled:before {
  opacity: 0.25;
}

.main-banner .slick-prev:before,
.main-banner .slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;

  opacity: 0.75;
  color: white;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main-banner .slick-prev {
  left: -25px;
}
[dir="rtl"] .slick-prev {
  right: -25px;
  left: auto;
}
.slick-prev:before {
  content: "←";
}
[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}
[dir="rtl"] .slick-next {
  right: auto;
  left: -25px;
}
.slick-next:before {
  content: "→";
}
[dir="rtl"] .slick-next:before {
  content: "←";
}

/* Dots */
.main-banner .slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.main-banner .slick-dots {
  position: absolute;
  bottom: -20px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  z-index: 1;
  list-style: none;
  text-align: center;
}
.main-banner .slick-dots li {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 1px;
  padding: 0;
  cursor: pointer;
  transition: width 0.3s ease-in-out;
}
.main-banner .slick-dots li button {
  font-size: 0;
  line-height: 0;

  display: block;

  width: 10px;
  height: 10px;
  padding: 5px;

  cursor: pointer;

  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.main-banner .slick-dots li button:hover,
.main-banner .slick-dots li button:focus {
  outline: none;
}
.main-banner .slick-dots li button:hover:before,
.main-banner .slick-dots li button:focus:before {
  opacity: 1;
}
.main-banner .slick-dots li button:before {
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;

  position: absolute;
  top: 0;
  left: 0;

  width: 10px;
  height: 10px;

  content: "•";
  text-align: center;

  opacity: 0.25;
  color: black;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.main-banner .slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: black;
}

/* Custom Slick Dots */

@keyframes loading {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

.main-banner .ft-slick__dots--custom {
  height: 2px;
  width: 30px;
  background-color: rgba(255,255,255,.5);
  border-radius: 4px;
  position: relative;
  top: 0;
  
}

.main-banner .slick-dots li {
  width: 30px;
  vertical-align: top;
  /* margin: 0 2px; */
  transition: width 0.3s ease-in-out;
}

.main-banner .slick-dots .slick-active {
  width: 30px;
  transition: width 0.3s ease-in-out;
}

.main-banner .slick-dots .slick-active .ft-slick__dots--custom {
  width: 30px;
  overflow: hidden;
}
.main-banner .slick-dots .slick-active .ft-slick__dots--custom .loading {
  height: 4px;
  animation: loading 3s ease-in;
  background-image: linear-gradient(270deg, #ffffff, #ffffff);
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
}
.login-header-new .bg-transparent {padding:0px !important}

.login-header-new .bg-transparent svg {
  font-size: calc(2rem + 0.3vw) !important;
    fill: rgba(0,0,0,.54);
    width: 26px;
}
.v-divider {
  border-top: rgba(0,0,0,.12) thin solid;
  display: block;
  flex: 1 1;
  max-width: 100%;
  height: 0;
  max-height: 0;
  /* border: solid; */
  /* border-width: thin 0 0; */
  transition: inherit;
  margin: 0 10px;
}
/* .paise {
  margin-left: 30px;
} */
.login-banner .slider-items {
  border: none;
}
.login-banner .slick-dots {
  bottom: 15px;
}
.login-reg-wrapper figure img {
}

/*=====*/
.bg1 {
  background: url(assets/images/background1.jpg);
  background-position: center;
  background-size: cover;
  position: relative;
  padding: 50px 0;
  color: #fff;
  padding-top: 0px;
}
.index_title2 {
  padding: 10px;
  border: 1px solid #00000030;
  border-radius: 6px;
  margin-bottom: 15px;
  background: rgb(35, 38, 41, 0.5);
  padding:18px 0;
  align-items: center;
}
.afi-text {
  color: #fff; font-size: 12px;
  margin-bottom: 0;
}
.afi-text1 {
  color: #f3cca4;
  font-weight: bold;
  padding-bottom: 0;
}
.bg2 {
  position: relative;
  padding: 50px 0;
  color: #fff;
  background-image: url(assets/images/background2.jpg);
  background-position: center;
  background-size: cover;
  display: flex;
  border-top: #434141 thin solid;
}
.bg2 h2{letter-spacing: 6px;
  text-transform: uppercase;
  font-size: 16px; font-weight: 400;
  margin-bottom: 26px;}

  .bg2  .img-fluid {
    margin-top: 40px;
}

.bg2 p{font-size: 12px; margin-top: 10px;}

.index_exhibitor {
  background: url(assets/images/event_bg.webp);
  position: relative;
  padding: 50px 0;
  color: #fff;
  background-position: center;
  background-size: cover;
  display: flex;
}
.btn_index {
  background: rgb(128, 194, 169);
  background: linear-gradient(
    180deg,
    rgba(128, 194, 169, 1) 0%,
    rgba(33, 147, 103, 1) 29%,
    rgba(20, 112, 76, 1) 60%,
    rgba(15, 100, 67, 1) 91%
  );
  border-radius: 10px;
  color: #fff !important;
  padding: 10px 20px;
  font-size: 14px;
  border: 2px solid rgba(20, 112, 76, 1);
}

.referal_liks_top {
  display: block;
  text-align: center;
  padding: 10px 10px 0;
}

.referal_liks_top:last-child p {margin-bottom: 0px;}

.affilates-page .slick-slider {
  padding: 0 50px !important;
}

.affilates-page .slick-current + div img { width: 120%;
  border: 4px solid white;
  border-radius: 10px;
  box-shadow: 0px 0px 12px 7px rgba(255, 255, 255, 0.77);
}




@media only screen and (max-width: 1920px) and (min-width: 768px) {
  .main {
    padding: 13px 0;
    overflow-y: auto;
    margin-bottom: 35px;
    max-width: 75rem;
    margin: auto;
  }

  .main-slider-inner-div ul li div span {
    height: 120px;
  }
  .main-banner {
    height: auto;
  }
  .main-banner .slick-dots {
    bottom: -38px;
  }
  .payment-sec .payment-box img {
    max-height: 42px;
    max-width: 42px;
  }
  .pay {
    padding: 5rem;
  }
  .footer-bottom h6 {
    font-size: 14px;
  }
  .footer-bottom p {
    font-size: 14px;
  }
  .heading-block h3 {
    font-size: 1.25rem;
  }
  .heading-block a {
    font-size: 1.25rem;
  }
  .common-slider h6 {
    font-size: 1.25rem;
    margin-bottom: 0;
  }
  .main-slider-inner-div ul li div p {
    font-size: 2.5vw;
  }
  .main-slider-inner-div h5 {
    align-items: center;
    display: flex;
    
  }

  .marquee-notification.d-flex {
    max-width: 75rem;
    margin: 0 auto -13px;
  }
  .slider-items-banner {
    padding: 0;
  }
  .slider-items-banner figure img {
    border-radius: 0;
    width: 100% !important;
    height: auto !important;
}
  .betbtn1 {
    right: 3% !important;
    bottom: 15% !important;
  }

  .slider-items {
    border: none;
  }

  .login-banner {
    padding: 0 12rem;
    background: #bf0e80;
  }
  .login-banner .slick-dots {
    background: transparent;
  }
  .main-slider-inner-div ul li {
    width: 20%;
  }
}

.afilate-form {
  /* padding: 10px; */
  /* height: 100%; */
  background: #FECF0A !important;
  padding: 20px;
}
.afilate-form label{color: #fff;}

/* .afilate-form div .form-group{
  width: 100%;
} */

.afilate-form .react-tel-input .form-control {
  border: 1px solid grey !important;
  font-size: 15px !important;
  /* font-weight: 600 !important; */
}

.afilate-slider .afilate {
  margin-top: 30px;
  /* display: flex;
  align-items: center;
  flex-direction: column; */
  padding: 10px;
}
.afilate-slider .afilate h3 {
  padding-left: 12px;
}

.main-new {
  padding: 0 !important;
}
.afilate-slider .slick-prev {
  top: 43% !important;
  width: 30px !important;
  height: 40% !important;
  left: 15px !important;
  z-index: 999999999999999;
}
.afilate-slider .slick-next {
  top: 43% !important;
  width: 30px !important;
  height: 40% !important;
  right: 15px !important;
  z-index: 999999999999999;
}
.afilate-slider .affilate-slider {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
}
.afilate-slider .affilate-slider img {
  margin-bottom: 0;
  width: 100%;
  margin: 5px auto;
}
/* .slick-center{
  background-color: red !important;
} */
.afilate-slider .slick-center img {
  width: 120% !important;
  border: 4px solid white;
  border-radius: 10px;
  margin-top: -5px;
  -webkit-box-shadow: 0px 0px 12px 7px rgba(255, 255, 255, 0.77);
  -moz-box-shadow: 0px 0px 12px 7px rgba(255, 255, 255, 0.77);
  box-shadow: 0px 0px 12px 7px rgba(255, 255, 255, 0.77);
}
.afilate-slider .slick-center p{
  font-size: 17px !important;
}
.afilate-slider .affilate-slider p {
  font-size: 14px ;
}

.afilate-slider .slick-slide > div > div {
  padding: 10px;
}
.header-right-btn-group a{
color: #14805e;     
display: flex;
flex-direction: column;    
 margin-left: 5px;
text-align: center;
font-size: 3.2vw;
 font-weight: 700;
word-wrap: break-word;
word-break: break-all;
}
.header-right-btn-group a img{height: 21px;}
.header-right-btn-group {
  display: flex;     min-width: 90px;
}

.login-reg-wrapper button{    
  min-width: 60px;
  text-align: left;
}

  .footer-license {
    padding: 1.8666666667vw 0;
    border-bottom: .2666666667vw solid #333333;
}
.footer-license .license-title {
  color: #ffffff80;
  font-size: 3.2vw;
  font-weight: 700;
  line-height: 1.5;
}
.footer-license p {
  color: #ffffff80;
  font-size: 3.2vw;
  line-height: 1.2;
}

.promotion-box {
  position: relative;
  width: 94.6666666667vw;
  height: 68vw;
  overflow: hidden;
  border-radius: .8vw;
  background: #ffffff;
  color: #111;
  margin: 0 auto 2.6666666667vw;
}
.promotion-box .pic {
  position: relative;
  width: 100%;
  height: 31.4666666667vw;
}
.promotion-box .pic img {
  display: block;
  width: 100%;
  height: 100%;
}
.promotion-box .pic .item-bg {
  content: "";
  display: block;
  position: absolute;
  bottom: -1.3333333333vw;
  left: 0;
  width: 100%;
  mask-repeat: no-repeat;
  mask-position: center top;
  mask-size: 100%;
}
.promotion-box .promotion-box-inner {
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: 2.6666666667vw;
  padding: 2.6666666667vw;
}
.promotion-box .promotion-box-inner .text-main {
  display: block;
  width: 100%;
  max-height: 12.8vw;
  overflow: hidden;
  transition: all .6s;
}
.content-style h3 {
  margin-bottom: 1.3333333333vw;
  overflow: hidden;
  color: #333;
  font-size: 4.2666666667vw;
  font-weight: 700;
  line-height: 1.2;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.content-style p {
  min-height: 6.6666666667vw;
  margin: 2.6666666667vw 0;
  font-size: 3.4666666667vw;
  line-height: 1.5;
}
.content-style .times {
  position: relative;
  margin-bottom: 2.6666666667vw;
  font-size: 3.4666666667vw;
}
.content-style .button-box {
  display: flex;
}
.promotion-box .button.btn-primary {
  background: #0088da;
  position: relative;
  width: 100%;
  text-align: center;
  color: #fff;
  height: 32px;
  line-height: 32px;
  font-weight: 600;
}
.content-style .button-box .button:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  background: linear-gradient(to bottom,rgba(255,255,255,.2),rgba(255,255,255,0) 100%);
  width: 100%;
  height: 100%;
}
.promotion-box .new_tag {
  background-color: #008aff;
  color: #fff;
  position: absolute;
  top: 11px;
  left: 10px;
  font-size: 14px;
  padding: 0 5px;
}
.member-menu {
  position: fixed;
  padding-bottom: 80px ;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  transition: all .4s;
  background: #ffffff;
  -webkit-overflow-scrolling: touch;
}
.member-menu .close {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  width: 13.3333333333vw;
  height: 13.3333333333vw;
  border-radius: 0 0 0 100%;
  background: #ffffff;
}
.member-header {
  position: relative;
  width: 100%;
  height: 40vw;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: 100%;
  background-image: url("assets/images/member-header-bg.png");

}
.member-header .member-header-content {
  bottom: 18.6666666667vw;
  left: 2.6666666667vw;
  text-align: center;
}
.member-header-content {
  position: absolute;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  bottom: 32vw;
  left: 2.6666666667vw;
  z-index: 1;
}
.member-header-content .pic {
  display: inline-block;
  width: 17.3333333333vw;
  height: 17.3333333333vw;
  margin-right: 2.6666666667vw;
  border-radius: 17.3333333333vw;
  background-color: #111;
  background-repeat: no-repeat;
  color: #14805e;
  font-size: 9.6vw;
  line-height: 17.3333333333vw;
  text-align: center;
  vertical-align: middle;
  box-shadow: 2px 4px 7px #0000003d;
  background-position: center;
  background-size: contain;
}
.member-header-content .infor {
  display: inline-block;
  width: 74.6666666667vw;
  vertical-align: middle;
}
.member-header-content .account {
  
  color: #fff;
  font-size: 4.2666666667vw;
}
.member-header-content .account span {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  width: 100%;
  max-height: 17.0666666667vw;
  overflow: hidden;
  word-wrap: break-word;
  font-size: 4.2666666667vw;
}
.member-header-content .vip-points {
  display: inline-flex;
  position: relative;
  flex-flow: row nowrap;
  align-items: center;
  max-width: 100%;
  width: auto;
  min-height: 6.4vw;
  padding: .5333333333vw 2.6666666667vw;
  margin-bottom: .5333333333vw;
  border-radius: 6.4vw;
  background: #262626;
  color: #fff;
  font-size: 3.2vw;
  white-space: nowrap;
  line-height: 1.5;
  overflow: scroll;
}
.member-header-content .vip-points.active span {
  flex: 0 1 25px;
  max-width: none;
  overflow: visible;
}
.member-header-content .vip-points .myvip-text .item-icon {
  display: inline-block;
  flex: 0 0 2.9333333333vw;
  width: 2.9333333333vw;
  height: 2.6666666667vw;
  margin: 0 0 0 1.3333333333vw;
  background: #ffffff;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center center;
  mask-position: center center;
  -webkit-mask-size: cover;
  mask-size: cover;
  vertical-align: middle;
}
.member-menu-content.bonuswallet {
  margin: -10.6666666667vw 0 0;
}
.member-menu-content {
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  height: auto;
  margin: -29.3333333333vw 0 0;
  padding: 0 2.6666666667vw;
}
.member-menu-box.balance-box {
  display: flex;
  position: relative;
  flex-direction: row;
}
.member-menu-box {
  width: 100%;
  height: auto;
  margin: 0 0 2.6666666667vw;
  border-radius: .8vw;
  background: #333333;
}
.member-menu-box.balance-box .balance.balance-row {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.member-menu-box.balance-box .balance .text {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #7dbfaa;
  line-height: 4.8vw;
  white-space: nowrap;
}
.member-menu-box .title {
  width: 100%;
  padding: 0 2.6666666667vw;
  /* border-bottom: .2666666667vw solid rgba(153,153,153,.3); */
  line-height: 9.3333333333vw;
}
.member-menu-box .title h2 {
  color: #3a3a3a;
  font-size: 14px;
  margin: 8px 0;
}
sup{
  color:red;
}
/* .member-menu-box .title h2:before {
  content: "";
  display: inline-block;
  width: 1.0666666667vw;
  height: 4.2666666667vw;
  margin-right: 1.3333333333vw;
  background: #14805e;
  vertical-align: middle;
} */
.member-menu-box .title h2 span {
  display: inline-block;
  vertical-align: middle;
}
.member-menu-box ul {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 12px;
  margin: 0;
}
.member-menu-box ul.align-center li {
  flex: auto;
  width: 33.3333333333%;
}
.member-menu-box ul li a {
  display: block;
  text-decoration: none;
}
.member-menu-box ul li a .item-icon {
  display: block;
  width: 9.3333333333vw;
  height: 9.3333333333vw;
  margin: 0 auto 1.3333333333vw;
  border-radius: 9.3333333333vw;
  background: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}.member-menu-box ul li a p {
  color: #fff; margin: 0;
  font-size: 3.4666666667vw;
  text-align: center;
}
.member-menu-logout {
  width: 100%;
  height: auto;
  margin: 0 0 2.6666666667vw;
  border-radius: .8vw;
  background: #333333;
}
.member-menu-logout a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.6666666667vw;
  text-decoration: none;
}
.member-menu-logout a p {
  display: inline-block;
  color: #fff;
  font-size: 3.4666666667vw;
  text-align: center;
  margin: 0;
}
.member-menu-box.balance-box .balance {
  display: inline-flex;
  position: relative;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  height: 18.4vw;
  padding: 2.6666666667vw;
  font-size: 3.2vw;
}
.member-menu-box.balance-box .balance .amount i {
  display: inline-block;
  position: relative;
  transition: all .1s ease-in;
  color: #ffdf1a;
}
.member-menu .close:after {
  transform: rotate(-45deg);
}
.member-menu .close:before {
  transform: rotate(45deg);
}
.member-menu .close:before, .member-menu .close:after {
  content: "";
  display: block;
  position: absolute;
  top: 40%;
  left: 60%;
  width: 5.3333333333vw;
  height: .5333333333vw;
  margin: -.2666666667vw 0 0 -2.6666666667vw;
  border-radius: .5333333333vw;
  background: #ffffff;
}
.p-title  svg{
  color: #ffff;
}

.common-slider .slick-slider{
  overflow-x: auto; 
    overflow-y: hidden; 
}
.common-slider .slick-dots li button{
  background-color:rgba(255,255,255,.5)
}
.common-slider .slick-dots li button:before{
  background-color:rgba(255,255,255,.5)
}
.common-slider .slick-dots{
  bottom: -15px;
}
.common-slider .slick-dots li{
  height: 17px;
}
.index_country p {
  padding-top: 10px;
  color: #f7f7f7;
  text-transform: uppercase;
  font-family: 'Roboto Condensed';
  letter-spacing: 1px;
  font-weight: 200;
  margin-bottom: 5px;
}
.index_country h3 {
  color: #ffffff;
  margin-bottom: 0;
  text-transform: uppercase;
  font-family: 'Roboto Condensed';
  letter-spacing: 1px;
  font-weight: 800;
  padding-bottom: 10px;
}
.afilate-form label {
  color: #000000;
}
.index_country .index_title2 {
  background: rgb(35, 38, 41, 0.5);
  padding: 30px 0;
  align-items: center;
}
.affiliate-data h4{
  font-size: 12px!important;
  color: #fff;
    margin-bottom: 0;
    text-align: left;
}

.affiliate-data h3{
  color:#f3cca4;font-weight:bold;padding-bottom:0;font-size: 15px;
}

section.index_brand {
  min-height: 0;
  background-image: url("https://bjaffiliates.com/img/country_option/background2mb.jpg");
  position: relative;
  padding: 50px 0;
  background-position: center;
  background-size: cover;
  display: flex;
}

.index_brand h2 {
  color: #fff; font-size: 16px;
  letter-spacing: 6px;
  text-transform: uppercase;
}
.index_brand p {
  color: #fff;
  margin-top: 0px;
  font-size: 12px;
    margin-bottom: 10px;
}

section.index_country{
  background-image: url("assets/images/background1mb.jpg");
  background-position: center;
  background-size: cover;
}
.afilate-form{
  background: rgb(35, 38, 41, 0.5);
    padding: 30px 15px;
}

.index_country .slick-initialized{ padding:0 34px;}
.index_country .slick-initialized .slider-items{padding: 0 15px;}
.index_country .slick-slide.slick-active.slick-current + .slick-slide.slick-active figure img {
  border: 4px solid white;
  border-radius: 10px;
  box-shadow: 0px 0px 12px 7px rgba(255,255,255,0.77);
  /* margin: 15px; */
  /* vertical-align: top; */
  /* scale: 120%; */
  width: 100%;
}

.index_country .slick-track{ display: flex !important; align-items: center;}
.index_country .slick-slide.slick-active.slick-current + .slick-slide.slick-active p {
  font-size: 15px;
  font-weight: bold;
}
.index_country p {
  font-size: 10px;
  text-align: center;
}
.index_country .slick-next{
  right: 10px!important;
  background-image: url("assets/images/Layer 10 copy 2.png")!important;height: 47px;width: 26px;
  background-size: 100%;
    background-repeat: no-repeat;
}
.index_country .slick-prev{
  left: 15px!important;
  z-index:999!important;
  background-image: url("assets/images/Layer 10 copy.png")!important;height:  47px;width: 26px;
  background-size: 100%;
    background-repeat: no-repeat;
}

.index_country .slick-next:before{
  display: none;
}
.index_country .slick-prev:before{
  display: none;
}
.index_country .slick-slide img{width:90%}
.index_country .slick-slide figure {
  margin: 10px 0 1rem;
  min-height: 68px;
}

.common-slider-box{
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  border-radius: 20px;
}
.common-slider-box .slider-items {
  display: inline-block;
  position: relative;
  width: 72vw;
  height: 41.3333333333vw;
  margin: 1.3333333333vw 2.6666666667vw 1.3333333333vw 0;
  border-radius: .8vw;
  background: #333333;
  box-shadow: 0 0 .8vw #000c;
  vertical-align: top;
}
.gamefixed {
  position: fixed;
  top: 54px;
  z-index: 9;
  width: 100%;
  background: #111;
  transition: all .3s;
}
.gamefixed .payment-box figure img{
  display: none;
}
.gamefixed .home-tabing-slider .payment-sec .payment-box{
padding: 0;

}
.gamefixed .payment-box figure{
  margin: 0!important;
}
.select-group li {
  display: inline-block;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.select-group.checkbox-style [type=radio]:checked+label {
  transition: all .3s!important;
  /* border: .2666666667vw solid #ffdf1a!important; */
  background: #fddd04!important;
  color: #000!important;
}
.payment-select-group.checkbox-style [type=radio]:checked+label {
  transition: all .3s!important;
  border: .2666666667vw solid #ffdf1a!important;
  background: transparent!important;
  color: #000!important;
}

 .select-group.checkbox-style [type=radio]+label {
  padding: 1.3333333333vw 2.6666666667vw;
}
.select-group [type=radio]+label {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  border: .2666666667vw solid #5e5e5b;
  border-radius: .8vw;
  background: transparent;
  color: #333;
  font-size: 3.2vw;
  line-height: 9.3333333333vw;
  text-align: center;
  padding: 2vw 2.6666666667vw;
  border-radius: 20px;
}

.payment-select-group [type=radio]+label {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  border: .2666666667vw solid #5e5e5b;
  border-radius: .8vw;
  background: transparent;
  color: #333;
  font-size: 3.2vw;
  line-height: 9.3333333333vw;
  text-align: center;
  padding: 2vw 2.6666666667vw;
  border-radius: 20px;
  background-color: #f5f5f5!important;
  border: 3px solid #bdbdbd!important;
  border-radius: 17px!important;
}

.deposit-amt-option label {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  border: none !important;
  border-radius: .8vw;
  background: transparent;
  color: #ffce5e !important;
  font-size: 3.2vw;
  line-height: 9.3333333333vw;
  text-align: center;
  padding: 2vw 2.6666666667vw;
  border-radius: 6px !important;
  background: #000 !important;
  font-weight: 500 !important;
}

.balance-details {
  /* height: 75px!important; */
  padding-top: 12px;
  color: #000;
  font-weight: 500;
  font-size: 16px;
  padding-bottom: 20px;
}
.v-application .pa-2 {
  padding: 8px!important;
}
.nav-item{
  width: 3rem;
  height: 3rem;
  margin: auto;
  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
}
.img-nav-item {
  width: 2rem;
  height: 2rem;
}
.theme--dark.v-sheet {
  background-color: #1e1e1e;
  border-color: #1e1e1e;
  color: #fff;
  border-radius: 10px;
  width: 3rem;
  height: 3rem;
  margin: auto 5px;
  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px !important;
}
.nav-item-span {
  font-size: .625rem;
  display: inline-block;
  width: 100%;
  text-align: center;
}
.pa-2.col-4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
}

.accordion-header svg{margin-right:12px}
.accordion-item{border: none !important;}

.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: transparent !important;
  box-shadow: none !important;
}
.v-list-item__title.childNav-title {
  color: #9b9b9b!important;
  font-size: .88rem!important;
  text-decoration: none;
  padding: 12px 0;
  font-weight: 600;
  outline: none;
}
.accordion-body a{ text-decoration: none !important;}




.select-group .bank {
  margin-bottom: 1.3333333333vw;
}
.select-group .bank img {
  display: block;
  width: auto;
  height: 6.6666666667vw;
}
.select-group.checkbox-style [type=checkbox]+label span, .select-group.checkbox-style [type=radio]+label span {
  line-height: 1.5;
}
.select-group [type=checkbox], .select-group [type=radio] {
  width: 105px;
  height: 34px;
  position: absolute;
  z-index: 9;
  opacity: 0;
}
.select-group  ul {
  display: grid;
  flex-wrap: wrap;
  width: 100%;
  padding: 12px;
  margin: 0;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill,calc((100% - 20px) / 3));
}
.select-group label span{
  line-height: 1.5;
}
.select-group.checkbox-style [type=radio]:checked+label .item-icon {
  display: block;
  position: absolute;
  z-index: 2;
  right: -.2666666667vw;
  bottom: 0.733333vw;
  width: 5.3333333333vw;
  height: 4.2666666667vw;
  opacity: 1;
 
}
.select-group.checkbox-style [type=radio]+label .item-icon{
  display: none;
}
.input-group.money {
  padding: 0 0 2.6666666667vw;
}
/* .input-group {
  display: flex;
  position: relative;
  flex-direction: column;
  padding-bottom: 2.6666666667vw;
  font-size: 3.2vw;
  background: #333333;
  line-height: 1.5;
} */
.input-group.money label {
  position: absolute;
  z-index: 2;
  top: 5.3333333333vw;
  left: 1.3333333333vw;
  transform: translateY(-50%);
  color: #14805e;
}
.input-group.money .input-wrap {
  z-index: 1;
}
.input-group.money .input-wrap input {
  color: #14805e;
  text-align: right;
}
.input-group input:disabled {
  border-bottom: .2666666667vw solid rgba(153,153,153,.3);
  border-radius: 0;
  background: none;
}
.delete-btn {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  width: 10.6666666667vw;
  height: 10.6666666667vw;
  transition: all .3s;
  background: #14805e;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: 30%;
  mask-size: 30%;
  opacity: 0;
}
.tips-info {
  position: relative;
  flex: 1;
  margin: 0;
  padding: 2.6666666667vw;
  border: .2666666667vw solid #0a3e2d;
  border-radius: 1.3333333333vw;
  background: #03120d;
  text-align: left;
  white-space: normal;
}
.player-deposit-wrap .tips-info.note h5, .withdraw-wallet .tips-info.note h5 {
  width: 100%;
  margin-bottom: 0;
  align-items: flex-start;
}
.player-deposit-wrap .tips-info.note span, .withdraw-wallet .tips-info.note span {
  color: #fff;
  font-size: 3.2vw;
  line-height: 1.2;
  white-space: pre-wrap;
}

.usrTrans-form .member-menu-box .title {
  width: 100%;
  padding: 0;
  /* border-bottom: .2666666667vw solid rgba(153,153,153,.3); */
  line-height: 9.3333333333vw;
  margin-bottom: 15px;     display: flex;
  justify-content: space-between;
}
.usrTrans-form .member-menu-box .title i {
  display: flex;
  color: #999;
  font-size: 2.6666666667vw;
  white-space: nowrap;
}
.usrTrans-form .member-menu-box {
  border-radius: .8vw;
  width: 100%;
  height: auto;
  margin: 0 0 2.6666666667vw 0;
  padding: 2vw 0vw 0vw 0vw;
  background: transparent;
}
.usrTrans-form .select-group ul{ padding: 0px !important;}

.select-group  ul.col4 {
  display: grid;
  flex-wrap: wrap;
  width: 100%;
  padding: 12px;
  margin: 0;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill,calc((100% - 30px)/4));
}
.input-group.money .input-wrap input {
  color: #14805e;
  text-align: right;
  width: 100%;
  background: transparent;
  border: none;
  padding: 10px 10px 0 0;
}

.tips-info.note span{color: #fff;
  font-size: 3.2vw;
  line-height: 1.2;
  white-space: pre-wrap;}


  .refer-header {
    align-items: center;
    background-color: #14805e;
    color: #fff;
    display: flex;
    height: 120px;
    justify-content: center;
}
.refer-main {
  background-color: #fff;
  border-radius: 10px;
  height: 100%;
  margin-top: -10px;
  padding: 27px 17px;
  padding-bottom: 50px!important;
  position: relative;
}
.refer-main-top {
  margin-bottom: 15px;
}
.refer-button button:first-child {
  background-color: green;
  color: #fff;
  border: navajowhite;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 8px 12px;
}
.refer-button button:last-child {
  background-color: grey;
  color: #fff;
  border: navajowhite;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 8px 12px;
}
.index_country .input-group {
  padding-bottom: 0.25rem!important;
  background: none;
  
}

.index_country .input-group .form-control {
  padding: 0.25rem!important;
  width: 100%;
  border-radius:  0.375rem!important;
}

.index_country .input-group span{
  cursor: pointer;
    position: absolute;
    right: 0;
    border: none;
    top: 3px;
}

.index_country .slick-prev:hover, .index_country .slick-prev:focus, .index_country .slick-next:hover, .index_country .slick-next:focus {

  background-size: 100%!important;
    background-repeat: no-repeat!important;


}
.top-class{
  overflow: auto;
  padding-top: 52px;
}
.refer-share-button {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}


.menu-second ul {
  position: absolute;
  top: 0;
  left: -29.3333333333vw;
  width: 29.3333333333vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  transition: all .5s;
  background: #333333;     
  z-index: 999;
  box-shadow: 0 0 #0000; 
  padding: 0px 0 50px;
  -webkit-overflow-scrolling: touch; 
  text-align: center
}
.menu-second ul.active {
  left: 208px;
  box-shadow: .8vw 0 .8vw #0000004d;
}

.menu-second ul li {
  width: 75%;
  height: auto;
  margin: 0 auto;
  padding: 5.3333333333vw 0;
  border-bottom: .2666666667vw solid #464646;
}

.menu-second ul li figure{ margin: 0 !important;}

.menu-second ul li figure img{width:10.6666666667vw}

.sidebar-wrapper ul li.active {
  border-left: 0.8vw solid #14805e;
  background: #333333;
  
}
.sidebar-wrapper ul li.active span {

  color: #fff;
}
.submit-btn {
  width: 100%;
  height: 40px;
  font-size: 4vw;
  line-height: 9vw;
  margin: 2.6666666667vw auto;
  position: relative;
  border-radius: 10px;
  background: #0066d1!important;
  color: #fff;
  border: none;
  text-align: center;
}
.submit-btn1 {
  width: 100%;
  height: 40px;
  font-size: 4vw;
  line-height: 12vw;
  margin: 2.6666666667vw auto;
  position: relative;
  border-radius: 10px;
  background: #fddd04!important;
  color: #000 !important;
  border: none;
  text-align: center;
  display: block;
  text-decoration: none;
  line-height: 37px;
}
.float-banner {
  position: fixed;
  z-index: 7999;
  bottom: 72px;
  left: 10px;
  filter: drop-shadow(0 0 1.6vw rgba(0,0,0,.5));
}
.float-banner .close {
  position: absolute;
  z-index: 2;
  top: 1vw;
  right: -4vw;
  width: 6.6666666667vw;
  height: 6.6666666667vw;
  border-radius: 100%;
  opacity: 1;
  background: #f5f5f5;
  font-size: 15px;
    text-align: center;
    text-decoration: none;
}
.float-banner img {
  width: auto;
  height: auto;
  min-width: 13.3333333333vw;
  min-height: 13.3333333333vw;
  max-width: 32vw;
  max-height: 32vw;
}
.games .games-main {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 2.1333333333vw;
  position: relative;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 0 0.066667vw;
}
.games .games-box {
  position: relative;
  width: 25vw;
  height: auto;
  margin: 0 1.3333333333vw 2.6666666667vw;
  overflow: hidden;
  border-radius: 0.8vw;
}
.games .pic {
  position: relative;
  width: 100%;
  height: auto;
  line-height: 0;
}
.games .pic img {
  width: 100%;
  height: 20vw;
}
.games .games-box .text {
  display: inline-flex;
  position: relative;
  width: 100%;
  height: auto;
  padding: 6px 0px 0px 8px;
  background: #198754;
}
.games .games-box .text h3 {
  width: 83%;
  overflow: hidden;
  color: #fff;
  font-size: 3vw;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.games h5 {
  margin: 15px;
}
.games h5:before {
  content: "";
  display: inline-block;
  width: 1.0666666667vw;
  height: 4vw;
  margin-right: 1.3333333333vw;
  background-color: #198754;
}
.all-casino-page {
  padding-bottom: 30px;
  padding-top: 53px;
  background-color: #fff;
}
.search-tab ul{
  padding-top: 2.4vw!important;
    line-height: 0;
    text-align: left;
    width: 100%;
    height: 100%;
    padding: 7px 0px 10px 0.666667vw!important;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
}
.hot-listing li {
  
  /* background-color: #005dac!important;
  color: #fff; */
  background: #f5f5f5!important;
  color: #555!important;
  margin-right: 2.6666666667vw!important;
  padding: 1px 2.666667vw!important;
  border-radius: 0.8vw!important;
  font-size: 3.4666666667vw!important;
  line-height: 8vw!important;
  vertical-align: middle!important;
  width: auto!important;
}
.hot-listing li.active{
  
  background-color: #14805e!important;
  color: #fff!important; 
 
}
.search-tab {
  /* background: #ffffff; */
  /* margin-bottom: 4.2666666667vw; */
  display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    height: 13.3333333333vw;
    /* border-top: 0.2666666667vw solid #eeeeee; */
    /* border-bottom: 0.2666666667vw solid #eeeeee; */
}
.download-bar {
  /* position: fixed; */
  z-index: 5;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 12px;
  background-color: #f5f5f5;
  line-height: 1;
  top: 0;
}
.download-bar .download-bar-button {
  background: #ffce01!important;
  color: #333;
  height: 36px;
    min-width: 64px ;
    padding: 0 13px!important;
    font-size: 1rem!important;
    font-weight: 600;
    border-radius: 4px;
    border: none;
    line-height: 36px;
}
.download-bar .mr-2 {
  margin-right: 13px!important;
}
.download-bar .mr-1 {
  margin-right: 4px!important;
}
.download-bar .c-radis{
border-radius: 4px;
}
.download-bar-height{
  /* margin-top: 60px; */
}
.download-bar a{
  text-decoration: none;
    color: #333;
}
.slick-next{
  right: 0px!important;
}
.slick-prev{
  left: 0px!important;
  z-index: 99;
}
.gT_cricket{
  min-width: 94vw;
}
.gT_ld{
  min-width: 44vw;
    max-width: 44vw;
    height: 80px!important;
}
.gT_rng{
  min-width: 20vw;
    max-width: 20vw;
    height: 90px!important;
}

.common-slider-box {
  /* -webkit-overflow-scrolling: touch; */
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}
.common-slider-box .slider-items {
  border-radius: .8vw;
  display: inline-block;
  margin: 1.3333333333vw 2.6666666667vw 1.3333333333vw 0;
  position: relative;
  vertical-align: top;
  width: 40vw;
}
.common-slider-box .sports-tab-item {
  border-radius: .8vw;
  display: inline-block;
  position: relative;
  vertical-align: top;
  padding: 0px 5px;
  margin: 0;
}
.sports-tab-panel h3 {
  background-color: #000000;
  color: #fff;
  padding: 8px 9px;
}
.sports-tab-panel .sports-tab-item .active {
  background-color: #ffdf1a;
  color: #000;
}
.all-casino-tab{
  background-color: rgb(235 235 235)!important;
}
.game-page-card-menu.v-card {
  display: flex;
  justify-content:center;
  align-items: center;
  height: 48px;
  font-size: 1.4rem;
  max-height: 80px;
  background-color: #000!important;
  color: #ffce01!important;
}

.pulse:after, .pulse:before {
  content: "";
  position: absolute;
  left: -2px;
  top: -2px;
  right: -2px;
  bottom: -2px;
  border: 2px solid #fddd04;
  border-radius: 50%;
  animation: animate 2s linear infinite;
}
@keyframes animate {
  0% {
      box-shadow: 0 0 .0625rem #fddd04
  }

  30%,35% {
      box-shadow: 0 0 2.1875rem #fddd04
  }

  60%,65% {
      box-shadow: 0 0 0 #fddd04
  }

  to {
      box-shadow: 0 0 1px #fddd04
  }
}
.pulse {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12.1875rem;
  height: 12.1875rem;
  border-radius: 9.375rem;
  background: hsla(0,0%,100%,.9);
}
.v-overlay__content{
  position: relative;
}

/* affliate page css */

.et_pb_with_background {
  position: relative;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}

.et_pb_section {
  position: relative;
  background-color: #fff;
  background-position: 50%;
  background-size: cover;
}

@media (min-width: 981px) {
  .et_pb_section {
      padding: 4% 0;
  }
}

@media only screen and (min-width: 1350px) {
  .et_pb_section {
      padding: 54px 0;
  }
}

div.et_pb_section.et_pb_section_1 {
  background-image: url(assets/images/join-banner.png) !important;
}

.et_pb_row {
  width: 80%;
  max-width: 1080px;
  margin: auto;
  position: relative;
}

.et_pb_row {
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}

@media (min-width: 981px) {
  .et_pb_row {
      padding: 2% 0;
  }
}

@media only screen and (min-width: 1350px) {
  .et_pb_row {
      padding: 27px 0;
  }
}

.et_pb_row:after,
.et_pb_row_inner:after {
  content: "";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
  width: 0;
}

.et_pb_with_border {
  position: relative;
  border: 0 solid #333;
}

.et_pb_row_1 {
  border-color: RGBA(0, 0, 0, 0);
}

.et_pb_css_mix_blend_mode_passthrough {
  mix-blend-mode: unset !important;
}

.et_pb_column {
  float: left;
  background-size: cover;
  background-position: 50%;
  position: relative;
  z-index: 2;
  min-height: 1px;
}

.et_pb_column {
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}

@media (min-width: 981px) {
  .et_pb_column {
      margin-right: 5.5%;
  }

  .et_pb_column_4_4 {
      width: 100%;
  }
}

.et_pb_row .et_pb_column.et-last-child,
.et_pb_row .et_pb_column:last-child {
  margin-right: 0 !important;
}

@media (min-width: 981px) {

  .et_pb_row .et_pb_column.et-last-child,
  .et_pb_row .et_pb_column:last-child {
      margin-right: 0 !important;
  }

  .et_pb_column_1_3 {
      width: 29.6667%;
  }
}

.et_pb_module {
  position: relative;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}

.et_pb_module {
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-duration: .2s;
  animation-duration: .2s;
}

.et_pb_text {
  word-wrap: break-word;
}

.et_pb_text_0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-bottom: 16px !important;
}

.et_pb_module.et_pb_text_align_left {
  text-align: left;
}

.et_pb_text_0.et_pb_text {
  color: #b50f58 !important;
}

@media (min-width: 981px) {
  .et_pb_column_4_4 .et_pb_module {
      margin-bottom: 2.75%;
  }

  div.et_pb_row .et_pb_column .et_pb_module:last-child {
      margin-bottom: 0;
  }
}

.et_pb_image {
  margin-left: auto;
  margin-right: auto;
  line-height: 0;
}

.et_pb_image {
  display: block;
}

.et_pb_image_sticky {
  margin-bottom: 0 !important;
  display: inherit;
}

.et_pb_image_0 {
  margin-bottom: 16px !important;
  text-align: center;
}

@media (min-width: 981px) {
  .et_pb_column_1_3 .et_pb_module {
      margin-bottom: 9.27%;
  }
}

.et_pb_text_1 {
  font-family: 'Lato', Helvetica, Arial, Lucida, sans-serif;
  font-weight: 700;
  font-size: 16px;
  padding-top: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
}

.et_pb_module.et_pb_text_align_center {
  text-align: center;
}

.et_pb_text_1.et_pb_text {
  color: #FFFFFF !important;
}

.et_pb_text_1.et_pb_module {
  margin-left: auto !important;
  margin-right: auto !important;
}

.et_pb_image_1 {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  margin-bottom: 16px !important;
  text-align: center;
}

.et_pb_text_2 {
  font-family: 'Lato', Helvetica, Arial, Lucida, sans-serif;
  font-weight: 700;
  font-size: 16px;
  padding-top: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
}

.et_pb_text_2.et_pb_text {
  color: #FFFFFF !important;
}

.et_pb_text_2.et_pb_module {
  margin-left: auto !important;
  margin-right: auto !important;
}

.et_pb_image_2 {
  padding-bottom: 1px;
  margin-bottom: 16px !important;
  text-align: center;
}

.et_pb_text_3 {
  font-family: 'Lato', Helvetica, Arial, Lucida, sans-serif;
  font-weight: 700;
  font-size: 16px;
  padding-top: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
}

.et_pb_text_3.et_pb_text {
  color: #FFFFFF !important;
}

.et_pb_text_3.et_pb_module {
  margin-left: auto !important;
  margin-right: auto !important;
}

.et_pb_button_module_wrapper.et_pb_button_alignment_center {
  text-align: center;
}

@media only screen and (min-width: 981px) {
  .et_pb_text_4 {
      max-width: 60%;
  }
}

.et_pb_text_4.et_pb_text {
  color: #FFFFFF !important;
}

.et_pb_text_4.et_pb_module {
  margin-left: auto !important;
  margin-right: auto !important;
}

.et_pb_text_inner {
  position: relative;
}

.et_pb_text> :last-child {
  padding-bottom: 0;
}

span {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  vertical-align: baseline;
  background: transparent;
}

.et_pb_with_border .et_pb_image_wrap {
  border: 0 solid #333;
}

.et_pb_image .et_pb_image_wrap {
  display: inline-block;
  position: relative;
  max-width: 100%;
}

a:where(:not(.wp-element-button)) {
  text-decoration: underline;
}

a {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  vertical-align: baseline;
  background: transparent;
}

a {
  color: #2ea3f2;
}

a {
  text-decoration: none;
}

.et_pb_button {
  font-size: 20px;
  font-weight: 500;
  padding: .3em 1em;
  line-height: 1.7em !important;
  background-color: transparent;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  border: 2px solid;
  border-radius: 3px;
  -webkit-transition-duration: .2s;
  transition-duration: .2s;
  -webkit-transition-property: all !important;
  transition-property: all !important;
}

.et_pb_button {
  position: relative;
}

.et_pb_button_0 {
  transition: all 300ms ease 0ms;
}

.et_pb_button_module_wrapper>a {
  display: inline-block;
}

.et_pb_button_0_wrapper .et_pb_button_0 {
  padding-top: 8px !important;
  padding-right: 48px !important;
  padding-bottom: 8px !important;
  padding-left: 48px !important;
}

.et_pb_button_0 {
  color: #000000 !important;
  border-color: #b50f58;
  border-radius: 10px;
  letter-spacing: 1px;
  font-family: 'Lato', Helvetica, Arial, Lucida, sans-serif !important;
  font-weight: 900 !important;
  background-color: #b50f58;
}

.et_pb_button:after,
.et_pb_button:before {
  font-size: 32px;
  line-height: 1em;
  content: "\35";
  opacity: 0;
  position: absolute;
  margin-left: -1em;
  -webkit-transition: all .2s;
  transition: all .2s;
  text-transform: none;
  -webkit-font-feature-settings: "kern" off;
  font-feature-settings: "kern" off;
  font-variant: none;
  font-style: normal;
  font-weight: 400;
  text-shadow: none;
}

.et_pb_button:before {
  display: none;
}

#et-info-email:before,
#et-info-phone:before,
#et_search_icon:before,
.comment-reply-link:after,
.et-cart-info span::before,
.et-pb-arrow-next:before,
.et-pb-arrow-prev:before,
.et-social-icon a::before,
.et_audio_container .mejs-playpause-button button::before,
.et_audio_container .mejs-volume-button button::before,
.et_overlay:before,
.et_password_protected_form .et_submit_button::after,
.et_pb_button:after,
.et_pb_contact_reset:after,
.et_pb_contact_submit:after,
.et_pb_font_icon:before,
.et_pb_newsletter_button:after,
.et_pb_pricing_table_button:after,
.et_pb_promo_button:after,
.et_pb_testimonial:before,
.et_pb_toggle_title:before,
.form-submit .et_pb_button::after,
.mobile_menu_bar:before,
a.et_pb_more_button:after {
  font-family: ETmodules !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  -webkit-font-feature-settings: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 0 0;
  direction: ltr;
}

.et_pb_button_0,
.et_pb_button_0:after,
.et_pb_button_1,
.et_pb_button_1:after,
.et_pb_button_2,
.et_pb_button_2:after,
.et_pb_button_3,
.et_pb_button_3:after,
.et_pb_button_4,
.et_pb_button_4:after,
.et_pb_button_5,
.et_pb_button_5:after,
.et_pb_button_6,
.et_pb_button_6:after,
.et_pb_button_7,
.et_pb_button_7:after,
.et_pb_button_8,
.et_pb_button_8:after,
.et_pb_button_9,
.et_pb_button_9:after,
.et_pb_button_10,
.et_pb_button_10:after,
.et_pb_button_11,
.et_pb_button_11:after,
.et_pb_button_12,
.et_pb_button_12:after {
  transition: all 300ms ease 0ms;
}

a,
a:hover {
  text-decoration: none;
}

.et_pb_button:hover {
  background-color: hsla(0, 0%, 100%, .2);
}

.et_pb_button:hover,
.et_pb_module .et_pb_button:hover {
  border: 2px solid transparent;
  padding: .3em 2em .3em .7em;
}

.et_pb_bg_layout_light.et_pb_button:hover {
  background-color: rgba(0, 0, 0, .05);
}

.et_pb_button_0_wrapper .et_pb_button_0,
.et_pb_button_0_wrapper .et_pb_button_0:hover {
  padding-top: 8px !important;
  padding-right: 48px !important;
  padding-bottom: 8px !important;
  padding-left: 48px !important;
}

.et_pb_button:hover:after {
  opacity: 1;
  margin-left: 0;
}

h2 {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  vertical-align: baseline;
  background: transparent;
}

h2 {
  color: #333;
  padding-bottom: 10px;
  line-height: 1em;
  font-weight: 500;
}

h2 {
  font-size: 26px;
}

h2 {
  font-family: 'Roboto', Helvetica, Arial, Lucida, sans-serif;
}

.et_pb_text_0 h2 {
  font-family: 'Lato', Helvetica, Arial, Lucida, sans-serif;
  font-weight: 700;
  font-size: 40px;
  color: #b50f58 !important;
  text-align: center;
}

img {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  vertical-align: baseline;
  background: transparent;
}

img {
  max-width: 100%;
  height: auto;
}

.et_pb_image img {
  position: relative;
}

.et_pb_image_0 .et_pb_image_wrap img {
  height: 100%;
  width: auto;
}

p {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  vertical-align: baseline;
  background: transparent;
}

p {
  padding-bottom: 1em;
}

p:not(.has-background):last-of-type {
  padding-bottom: 0;
}

.et_pb_image_1 .et_pb_image_wrap img {
  height: 100%;
  width: auto;
}

.et_pb_image_2 .et_pb_image_wrap img {
  height: 100%;
  width: auto;
}

.et_pb_with_background {
  position: relative;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}

.et_pb_section {
  position: relative;
  background-color: #fff;
  background-position: 50%;
  background-size: cover;
}

@media (min-width: 981px) {
  .et_pb_section {
      padding: 4% 0;
  }
}

@media only screen and (min-width: 1350px) {
  .et_pb_section {
      padding: 54px 0;
  }
}

.et_pb_row {
  width: 80%;
  max-width: 1080px;
  margin: auto;
  position: relative;
}

.et_pb_row {
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}

@media (min-width: 981px) {
  .et_pb_row {
      padding: 2% 0;
  }
}

@media only screen and (min-width: 1350px) {
  .et_pb_row {
      padding: 27px 0;
  }
}

.et_pb_row:after,
.et_pb_row_inner:after {
  content: "";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
  width: 0;
}

div.et_pb_section.et_pb_section_2 {
  background-image: url(assets/images/BB88-NewCommission-bcg-4.png) !important;
}

.et_pb_row_4.et_pb_row {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-bottom: 8px !important;
}

.et_pb_css_mix_blend_mode_passthrough {
  mix-blend-mode: unset !important;
}

.et_pb_column {
  float: left;
  background-size: cover;
  background-position: 50%;
  position: relative;
  z-index: 2;
  min-height: 1px;
}

.et_pb_column {
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}

@media (min-width: 981px) {
  .et_pb_column {
      margin-right: 5.5%;
  }

  .et_pb_column_4_4 {
      width: 100%;
  }
}

.et_pb_row .et_pb_column.et-last-child,
.et_pb_row .et_pb_column:last-child {
  margin-right: 0 !important;
}

@media (min-width: 981px) {

  .et_pb_row .et_pb_column.et-last-child,
  .et_pb_row .et_pb_column:last-child {
      margin-right: 0 !important;
  }
}

.et_pb_module {
  position: relative;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}

.et_pb_module {
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-duration: .2s;
  animation-duration: .2s;
}

.et_pb_text {
  word-wrap: break-word;
}

.et_pb_module.et_pb_text_align_left {
  text-align: left;
}

@media (min-width: 981px) {
  .et_pb_column_4_4 .et_pb_module {
      margin-bottom: 2.75%;
  }

  div.et_pb_row .et_pb_column .et_pb_module:last-child {
      margin-bottom: 0;
  }
}

.et_pb_row_3.et_pb_row {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0px !important;
}

.et_pb_text_6 {
  font-family: 'Lato', Helvetica, Arial, Lucida, sans-serif;
  font-weight: 700;
  font-size: 36px;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}

.et_pb_module.et_pb_text_align_center {
  text-align: center;
}

.et_pb_text_7 {
  font-family: 'Lato', Helvetica, Arial, Lucida, sans-serif;
  font-weight: 700;
  font-size: 24px;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

@media only screen and (min-width: 981px) {
  .et_pb_text_7 {
      max-width: 80%;
  }
}

.et_pb_text_7.et_pb_module {
  margin-left: auto !important;
  margin-right: auto !important;
}

.et_pb_image {
  margin-left: auto;
  margin-right: auto;
  line-height: 0;
}

.et_pb_image {
  display: block;
}

.et_pb_image_sticky {
  margin-bottom: 0 !important;
  display: inherit;
}

.et_pb_image_3 {
  text-align: left;
  margin-left: 0;
}

.et_pb_text_8 {
  font-family: 'Lato', Helvetica, Arial, Lucida, sans-serif;
  font-weight: 700;
  font-size: 30px;
}

.et_pb_text_8.et_pb_text {
  color: #FECF0A !important;
}

.et_pb_image_4 {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  text-align: left;
  margin-left: 0;
}

.et_pb_text_inner {
  position: relative;
}

.et_pb_text> :last-child {
  padding-bottom: 0;
}

span {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  vertical-align: baseline;
  background: transparent;
}

.et_pb_image .et_pb_image_wrap {
  display: inline-block;
  position: relative;
  max-width: 100%;
}

@media (min-width: 981px) {
  .et_pb_module {
      margin-bottom: 9.27%;
  }
}

a:where(:not(.wp-element-button)) {
  text-decoration: underline;
}

.et_pb_button:after,
.et_pb_button:before {
  font-size: 32px;
  line-height: 1em;
  content: "\35";
  opacity: 0;
  position: absolute;
  margin-left: -1em;
  -webkit-transition: all .2s;
  transition: all .2s;
  text-transform: none;
  -webkit-font-feature-settings: "kern" off;
  font-feature-settings: "kern" off;
  font-variant: none;
  font-style: normal;
  font-weight: 400;
  text-shadow: none;
}

.et_pb_button:before {
  display: none;
}

#et-info-email:before,
#et-info-phone:before,
#et_search_icon:before,
.comment-reply-link:after,
.et-cart-info span::before,
.et-pb-arrow-next:before,
.et-pb-arrow-prev:before,
.et-social-icon a::before,
.et_audio_container .mejs-playpause-button button::before,
.et_audio_container .mejs-volume-button button::before,
.et_overlay:before,
.et_password_protected_form .et_submit_button::after,
.et_pb_button:after,
.et_pb_contact_reset:after,
.et_pb_contact_submit:after,
.et_pb_font_icon:before,
.et_pb_newsletter_button:after,
.et_pb_pricing_table_button:after,
.et_pb_promo_button:after,
.et_pb_testimonial:before,
.et_pb_toggle_title:before,
.form-submit .et_pb_button::after,
.mobile_menu_bar:before,
a.et_pb_more_button:after {
  font-family: ETmodules !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  -webkit-font-feature-settings: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 0 0;
  direction: ltr;
}

.et_pb_button_0,
.et_pb_button_0:after,
.et_pb_button_1,
.et_pb_button_1:after,
.et_pb_button_2,
.et_pb_button_2:after,
.et_pb_button_3,
.et_pb_button_3:after,
.et_pb_button_4,
.et_pb_button_4:after,
.et_pb_button_5,
.et_pb_button_5:after,
.et_pb_button_6,
.et_pb_button_6:after,
.et_pb_button_7,
.et_pb_button_7:after,
.et_pb_button_8,
.et_pb_button_8:after,
.et_pb_button_9,
.et_pb_button_9:after,
.et_pb_button_10,
.et_pb_button_10:after,
.et_pb_button_11,
.et_pb_button_11:after,
.et_pb_button_12,
.et_pb_button_12:after {
  transition: all 300ms ease 0ms;
}

a,
a:hover {
  text-decoration: none;
}

.et_pb_button:hover {
  background-color: hsla(0, 0%, 100%, .2);
}

.et_pb_button:hover,
.et_pb_module .et_pb_button:hover {
  border: 2px solid transparent;
  padding: .3em 2em .3em .7em;
}

.et_pb_bg_layout_light.et_pb_button:hover {
  background-color: rgba(0, 0, 0, .05);
}

.et_pb_button_0_wrapper .et_pb_button_0,
.et_pb_button_0_wrapper .et_pb_button_0:hover {
  padding-top: 8px !important;
  padding-right: 48px !important;
  padding-bottom: 8px !important;
  padding-left: 48px !important;
}

.et_pb_button:hover:after {
  opacity: 1;
  margin-left: 0;
}

h2 {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  vertical-align: baseline;
  background: transparent;
}

h2 {
  color: #333;
  padding-bottom: 10px;
  line-height: 1em;
  font-weight: 500;
}

h2 {
  font-size: 26px;
}

h2 {
  font-family: 'Roboto', Helvetica, Arial, Lucida, sans-serif;
}

.et_pb_text_0 h2 {
  font-family: 'Lato', Helvetica, Arial, Lucida, sans-serif;
  font-weight: 700;
  font-size: 40px;
  color: #b50f58 !important;
  text-align: center;
}

p {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  vertical-align: baseline;
  background: transparent;
}

p {
  padding-bottom: 1em;
}

p:not(.has-background):last-of-type {
  padding-bottom: 0;
}

img {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  vertical-align: baseline;
  background: transparent;
}

img {
  max-width: 100%;
  height: auto;
}

.et_pb_image img {
  position: relative;
}

.et_pb_text_9 {
  line-height: 1.2em;
  font-family: 'Lato', Helvetica, Arial, Lucida, sans-serif;
  font-weight: 700;
  font-size: 16px;
  padding-bottom: 0px !important;
}

.et_pb_text_9.et_pb_text {
  color: #FFFFFF !important;
}


/* These were inline style tags. Uses id+class to override almost everything */
#style-CYcgO.style-CYcgO {
  color: #FECF0A;
}

#style-8I4TX.style-8I4TX {
  color: #ffffff;
}

#style-QKJhY.style-QKJhY {
  color: #ffffff;
}

#style-baHpY.style-baHpY {
  color: #ffffff;
}

.et_pb_with_background {
  position: relative;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}

.et_pb_section {
  position: relative;
  background-color: #fff;
  background-position: 50%;
  background-size: cover;
}

@media (min-width: 981px) {
  .et_pb_section {
      padding: 4% 0;
  }
}

@media only screen and (min-width: 1350px) {
  .et_pb_section {
      padding: 54px 0;
  }
}

.et_pb_row {
  width: 80%;
  max-width: 1080px;
  margin: auto;
  position: relative;
}

.et_pb_row {
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}

@media (min-width: 981px) {
  .et_pb_row {
      padding: 2% 0;
  }
}

@media only screen and (min-width: 1350px) {
  .et_pb_row {
      padding: 27px 0;
  }
}

.et_pb_row:after,
.et_pb_row_inner:after {
  content: "";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
  width: 0;
}

.et_pb_css_mix_blend_mode_passthrough {
  mix-blend-mode: unset !important;
}

.et_pb_column {
  float: left;
  background-size: cover;
  background-position: 50%;
  position: relative;
  z-index: 2;
  min-height: 1px;
}

.et_pb_column {
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}

@media (min-width: 981px) {
  .et_pb_column {
      margin-right: 5.5%;
  }

  .et_pb_column_4_4 {
      width: 100%;
  }
}

.et_pb_row .et_pb_column.et-last-child,
.et_pb_row .et_pb_column:last-child {
  margin-right: 0 !important;
}

@media (min-width: 981px) {

  .et_pb_row .et_pb_column.et-last-child,
  .et_pb_row .et_pb_column:last-child {
      margin-right: 0 !important;
  }
}

.et_pb_module {
  position: relative;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}

.et_pb_module {
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-duration: .2s;
  animation-duration: .2s;
}

.et_pb_text {
  word-wrap: break-word;
}

.et_pb_module.et_pb_text_align_left {
  text-align: left;
}

@media (min-width: 981px) {
  .et_pb_column_4_4 .et_pb_module {
      margin-bottom: 2.75%;
  }

  div.et_pb_row .et_pb_column .et_pb_module:last-child {
      margin-bottom: 0;
  }
}

.et_pb_row_8 {
  min-height: 500px;
}

.et_pb_row_8.et_pb_row {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-bottom: -126px !important;
}

.et_pb_section_3 {
  min-height: auto;
}

.et_pb_section_3.et_pb_section {
  padding-top: 100px;
  padding-bottom: 165px;
  margin-bottom: 0px;
  margin-left: 0px;
}

div.et_pb_section.et_pb_section_3 {
  background-image:  url(assets/images/Slider-Alternative-Link-BCG.webp) !important;
}

.et_pb_text_11 {
  font-family: 'Lato', Helvetica, Arial, Lucida, sans-serif;
  font-size: 16px;
  padding-top: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
}

@media only screen and (min-width: 981px) {
  .et_pb_text_11 {
      width: 60%;
  }
}

.et_pb_text_11.et_pb_text {
  color: #000000 !important;
}

.et_pb_text_11.et_pb_module {
  margin-left: 0px !important;
  margin-right: auto !important;
}

.et_pb_button_module_wrapper.et_pb_button_alignment_left {
  text-align: left;
}

.et_pb_text_inner {
  position: relative;
}

.et_pb_text> :last-child {
  padding-bottom: 0;
}

@media (min-width: 981px) {
  .et_pb_module {
      margin-bottom: 9.27%;
  }
}

span {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  vertical-align: baseline;
  background: transparent;
}

a:where(:not(.wp-element-button)) {
  text-decoration: underline;
}

a {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  vertical-align: baseline;
  background: transparent;
}

a {
  color: #2ea3f2;
}

a {
  text-decoration: none;
}

.et_pb_button {
  font-size: 20px;
  font-weight: 500;
  padding: .3em 1em;
  line-height: 1.7em !important;
  background-color: transparent;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  border: 2px solid;
  border-radius: 3px;
  -webkit-transition-duration: .2s;
  transition-duration: .2s;
  -webkit-transition-property: all !important;
  transition-property: all !important;
}

.et_pb_button {
  position: relative;
}

.et_pb_button_1 {
  transition: all 300ms ease 0ms;
}

.et_pb_button_module_wrapper>a {
  display: inline-block;
}

.et_pb_button_1_wrapper .et_pb_button_1 {
  padding-top: 8px !important;
  padding-right: 32px !important;
  padding-bottom: 8px !important;
  padding-left: 32px !important;
}

.et_pb_button_1 {
  color: #000000 !important;
  border-color: #FECF0A !important;
  border-radius: 10px !important;
  letter-spacing: 2px;
  font-family: 'Lato', Helvetica, Arial, Lucida, sans-serif !important;
  font-weight: 700 !important;
  background-color: #FECF0A !important;
}

.et_pb_button:after,
.et_pb_button:before {
  font-size: 32px;
  line-height: 1em;
  content: "\35";
  opacity: 0;
  position: absolute;
  margin-left: -1em;
  -webkit-transition: all .2s;
  transition: all .2s;
  text-transform: none;
  -webkit-font-feature-settings: "kern" off;
  font-feature-settings: "kern" off;
  font-variant: none;
  font-style: normal;
  font-weight: 400;
  text-shadow: none;
}

.et_pb_button:before {
  display: none;
}

#et-info-email:before,
#et-info-phone:before,
#et_search_icon:before,
.comment-reply-link:after,
.et-cart-info span::before,
.et-pb-arrow-next:before,
.et-pb-arrow-prev:before,
.et-social-icon a::before,
.et_audio_container .mejs-playpause-button button::before,
.et_audio_container .mejs-volume-button button::before,
.et_overlay:before,
.et_password_protected_form .et_submit_button::after,
.et_pb_button:after,
.et_pb_contact_reset:after,
.et_pb_contact_submit:after,
.et_pb_font_icon:before,
.et_pb_newsletter_button:after,
.et_pb_pricing_table_button:after,
.et_pb_promo_button:after,
.et_pb_testimonial:before,
.et_pb_toggle_title:before,
.form-submit .et_pb_button::after,
.mobile_menu_bar:before,
a.et_pb_more_button:after {
  font-family: ETmodules !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  -webkit-font-feature-settings: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 0 0;
  direction: ltr;
}

.et_pb_button_0,
.et_pb_button_0:after,
.et_pb_button_1,
.et_pb_button_1:after,
.et_pb_button_2,
.et_pb_button_2:after,
.et_pb_button_3,
.et_pb_button_3:after,
.et_pb_button_4,
.et_pb_button_4:after,
.et_pb_button_5,
.et_pb_button_5:after,
.et_pb_button_6,
.et_pb_button_6:after,
.et_pb_button_7,
.et_pb_button_7:after,
.et_pb_button_8,
.et_pb_button_8:after,
.et_pb_button_9,
.et_pb_button_9:after,
.et_pb_button_10,
.et_pb_button_10:after,
.et_pb_button_11,
.et_pb_button_11:after,
.et_pb_button_12,
.et_pb_button_12:after {
  transition: all 300ms ease 0ms;
}

.et_pb_button[data-icon]:not([data-icon=""]):after {
  content: attr(data-icon);
}

.et_pb_section .et_pb_button_1::after,
.et_pb_section .et_pb_button_2::after,
.et_pb_section .et_pb_button_3::after,
.et_pb_section .et_pb_button_4::after,
.et_pb_section .et_pb_button_5::after,
.et_pb_section .et_pb_button_6::after,
.et_pb_section .et_pb_button_7::after,
.et_pb_section .et_pb_button_8::after,
.et_pb_section .et_pb_button_9::after,
.et_pb_section .et_pb_button_10::after,
.et_pb_section .et_pb_button_11::after,
.et_pb_section .et_pb_button_12::after {
  line-height: inherit;
  font-size: inherit !important;
  margin-left: -1em;
  left: auto;
  font-family: ETmodules !important;
  font-weight: 400 !important;
}

a,
a:hover {
  text-decoration: none;
}

.et_pb_button:hover {
  background-color: hsla(0, 0%, 100%, .2);
}

.et_pb_button:hover,
.et_pb_module .et_pb_button:hover {
  border: 2px solid transparent;
  padding: .3em 2em .3em .7em;
}

.et_pb_bg_layout_light.et_pb_button:hover {
  background-color: rgba(0, 0, 0, .05);
}

.et_pb_button_1_wrapper .et_pb_button_1,
.et_pb_button_1_wrapper .et_pb_button_1:hover {
  padding-top: 8px !important;
  padding-right: 32px !important;
  padding-bottom: 8px !important;
  padding-left: 32px !important;
}

.et_pb_button:hover:after {
  opacity: 1;
  margin-left: 0;
}

.et_pb_section .et_pb_button_1:hover::after,
.et_pb_section .et_pb_button_2:hover::after,
.et_pb_section .et_pb_button_3:hover::after,
.et_pb_section .et_pb_button_4:hover::after,
.et_pb_section .et_pb_button_5:hover::after,
.et_pb_section .et_pb_button_6:hover::after,
.et_pb_section .et_pb_button_7:hover::after,
.et_pb_section .et_pb_button_8:hover::after,
.et_pb_section .et_pb_button_9:hover::after,
.et_pb_section .et_pb_button_10:hover::after,
.et_pb_section .et_pb_button_11:hover::after,
.et_pb_section .et_pb_button_12:hover::after {
  margin-left: .3em;
  left: auto;
  opacity: 1;
}

.et_pb_button_2 {
  transition: all 300ms ease 0ms;
}

.et_pb_button_1_wrapper .et_pb_button_1,
.et_pb_button_1_wrapper .et_pb_button_1:hover,
.et_pb_button_2_wrapper .et_pb_button_2,
.et_pb_button_2_wrapper .et_pb_button_2:hover,
.et_pb_button_3_wrapper .et_pb_button_3,
.et_pb_button_3_wrapper .et_pb_button_3:hover,
.et_pb_button_4_wrapper .et_pb_button_4,
.et_pb_button_4_wrapper .et_pb_button_4:hover,
.et_pb_button_5_wrapper .et_pb_button_5,
.et_pb_button_5_wrapper .et_pb_button_5:hover,
.et_pb_button_6_wrapper .et_pb_button_6,
.et_pb_button_6_wrapper .et_pb_button_6:hover,
.et_pb_button_7_wrapper .et_pb_button_7,
.et_pb_button_7_wrapper .et_pb_button_7:hover,
.et_pb_button_8_wrapper .et_pb_button_8,
.et_pb_button_8_wrapper .et_pb_button_8:hover,
.et_pb_button_9_wrapper .et_pb_button_9,
.et_pb_button_9_wrapper .et_pb_button_9:hover,
.et_pb_button_10_wrapper .et_pb_button_10,
.et_pb_button_10_wrapper .et_pb_button_10:hover,
.et_pb_button_11_wrapper .et_pb_button_11,
.et_pb_button_11_wrapper .et_pb_button_11:hover,
.et_pb_button_12_wrapper .et_pb_button_12,
.et_pb_button_12_wrapper .et_pb_button_12:hover {
  padding-top: 8px !important;
  padding-right: 32px !important;
  padding-bottom: 8px !important;
  padding-left: 32px !important;
}

.et_pb_button_2 {
  color: #000000 !important;
  border-color: #b50f58;
  border-radius: 10px;
  letter-spacing: 2px;
  font-family: 'Lato', Helvetica, Arial, Lucida, sans-serif !important;
  font-weight: 700 !important;
  background-color: #b50f58;
}

#et-info-email::before,
#et-info-phone::before,
#et_search_icon::before,
.comment-reply-link::after,
.et-cart-info span::before,
.et-pb-arrow-next::before,
.et-pb-arrow-prev::before,
.et-social-icon a::before,
.et_audio_container .mejs-playpause-button button::before,
.et_audio_container .mejs-volume-button button::before,
.et_overlay::before,
.et_password_protected_form .et_submit_button::after,
.et_pb_button::after,
.et_pb_contact_reset::after,
.et_pb_contact_submit::after,
.et_pb_font_icon::before,
.et_pb_newsletter_button::after,
.et_pb_pricing_table_button::after,
.et_pb_promo_button::after,
.et_pb_testimonial::before,
.et_pb_toggle_title::before,
.form-submit .et_pb_button::after,
.mobile_menu_bar::before,
a.et_pb_more_button::after {
  font-family: ETmodules !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  -webkit-font-feature-settings: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 0 0;
  direction: ltr;
}

.et_pb_button_3 {
  transition: all 300ms ease 0ms;
}

.et_pb_button_3 {
  color: #000000 !important;
  border-color: #b50f58;
  border-radius: 10px;
  letter-spacing: 2px;
  font-family: 'Lato', Helvetica, Arial, Lucida, sans-serif !important;
  font-weight: 700 !important;
  background-color: #b50f58;
}

.et_pb_button_5 {
  transition: all 300ms ease 0ms;
}

.et_pb_button_5 {
  color: #000000 !important;
  border-color: #b50f58;
  border-radius: 10px;
  letter-spacing: 2px;
  font-family: 'Lato', Helvetica, Arial, Lucida, sans-serif !important;
  font-weight: 700 !important;
  background-color: #b50f58;
}

p {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  vertical-align: baseline;
  background: transparent;
}

p {
  padding-bottom: 1em;
}

p:not(.has-background):last-of-type {
  padding-bottom: 0;
}

.et_pb_text_10 {
  font-family: 'Lato', Helvetica, Arial, Lucida, sans-serif;
  font-weight: 900;
  font-size: 40px;
  padding-top: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  width: 100%;
}

.et_pb_text_10.et_pb_text {
  color: #000000 !important;
}

strong {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  vertical-align: baseline;
  background: transparent;
}

strong {
  font-weight: 700;
}


/* These were inline style tags. Uses id+class to override almost everything */
#style-lMhHm.style-lMhHm {
  color: #ffffff;
}

#style-mLRA3.style-mLRA3 {
  text-align: left;
}

#style-T6aow.style-T6aow {
  color: #ffffff;
}

.et_pb_with_background {
  position: relative;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}

.et_pb_section {
  position: relative;
  background-color: #fff;
  background-position: 50%;
  background-size: cover;
}

@media (min-width: 981px) {
  .et_pb_section {
      padding: 4% 0;
  }
}

@media only screen and (min-width: 1350px) {
  .et_pb_section {
      padding: 54px 0;
  }
}

.et_pb_row {
  width: 80%;
  max-width: 1080px;
  margin: auto;
  position: relative;
}

.et_pb_row {
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}

@media (min-width: 981px) {
  .et_pb_row {
      padding: 2% 0;
  }
}

@media only screen and (min-width: 1350px) {
  .et_pb_row {
      padding: 27px 0;
  }
}

.et_pb_row:after,
.et_pb_row_inner:after {
  content: "";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
  width: 0;
}

.et_pb_css_mix_blend_mode_passthrough {
  mix-blend-mode: unset !important;
}

.et_pb_column {
  float: left;
  background-size: cover;
  background-position: 50%;
  position: relative;
  z-index: 2;
  min-height: 1px;
}

.et_pb_column {
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}

@media (min-width: 981px) {
  .et_pb_gutters3 .et_pb_column {
      margin-right: 5.5%;
  }
}

.et_pb_row .et_pb_column.et-last-child,
.et_pb_row .et_pb_column:last-child {
  margin-right: 0 !important;
}

@media (min-width: 981px) {

  .et_pb_row .et_pb_column.et-last-child,
  .et_pb_row .et_pb_column:last-child {
      margin-right: 0 !important;
  }
}

.et_pb_row_11 {
  display: flex;
}

@media (min-width: 981px) {
  .et_pb_row.et_pb_equal_columns {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
  }
}

.et_pb_row_11.et_pb_row {
  padding-bottom: 0px;
  margin-left: auto !important;
  margin-right: auto !important;
}

.et_pb_section_7.et_pb_section {
  padding-top: 13px;
  background-color: #242424 !important;
}

.et_pb_column_14 {
  padding-top: 12px;
  padding-right: 10px;
  padding-bottom: 12px;
  padding-left: 10px;
}

@media (min-width: 981px) {

  .et_pb_gutters3 .et_pb_column,
  .et_pb_gutters3.et_pb_row .et_pb_column {
      margin-right: 5.5%;
  }

  .et_pb_gutters3 .et_pb_column_1_3,
  .et_pb_gutters3.et_pb_row .et_pb_column_1_3 {
      width: 29.6667%;
  }

  .et_pb_row.et_pb_equal_columns>.et_pb_column {
      -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
      order: 1;
  }
}

.et_pb_column_15 {
  padding-top: 12px;
  padding-right: 10px;
  padding-bottom: 12px;
  padding-left: 10px;
}

.et_pb_module {
  position: relative;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}

.et_pb_module {
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-duration: .2s;
  animation-duration: .2s;
}

.et_pb_text {
  word-wrap: break-word;
}

@media (min-width: 981px) {
  .et_pb_module {
      margin-bottom: 2.75%;
  }

  div.et_pb_row .et_pb_column .et_pb_module:last-child {
      margin-bottom: 0;
  }
}

.et_pb_module.et_pb_text_align_center {
  text-align: center;
}

.et_pb_text_26 {
  line-height: 1.6em;
  font-size: 13px;
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.et_pb_text_26.et_pb_text {
  color: #FFFFFF !important;
}

@media (min-width: 981px) {

  .et_pb_gutters3 .et_pb_column_1_3 .et_pb_module,
  .et_pb_gutters3.et_pb_row .et_pb_column_1_3 .et_pb_module {
      margin-bottom: 9.27%;
  }
}

.et_pb_text_27 {
  line-height: 1.6em;
  font-size: 13px;
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.et_pb_text_27.et_pb_text {
  color: #FFFFFF !important;
}

.et_pb_text_28 {
  line-height: 1.6em;
  font-size: 13px;
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.et_pb_text_28.et_pb_text {
  color: #FFFFFF !important;
}

.et_pb_text_29 {
  line-height: 1.6em;
  font-size: 13px;
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.et_pb_text_29.et_pb_text {
  color: #FFFFFF !important;
}

.et_pb_text_30 {
  line-height: 1.6em;
  font-size: 13px;
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.et_pb_text_30.et_pb_text {
  color: #FFFFFF !important;
}

.et_pb_text_31 {
  line-height: 1.6em;
  font-size: 13px;
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.et_pb_text_31.et_pb_text {
  color: #FFFFFF !important;
}

.et_pb_text_inner {
  position: relative;
}

.et_pb_text> :last-child {
  padding-bottom: 0;
}

@media (min-width: 981px) {
  .et_pb_gutters3 .et_pb_column_1_3 .et_pb_module {
      margin-bottom: 9.27%;
  }

  .et_pb_gutters3 .et_pb_column_1_3 {
      width: 29.6667%;
  }
}

.et_pb_text_23 {
  line-height: 1.6em;
  font-size: 13px;
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.et_pb_text_23.et_pb_text {
  color: #FFFFFF !important;
}

.et_pb_column_13 {
  padding-top: 12px;
  padding-right: 10px;
  padding-bottom: 12px;
  padding-left: 10px;
}

h1 {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  vertical-align: baseline;
  background: transparent;
}

h1 {
  color: #333;
  padding-bottom: 10px;
  line-height: 1em;
  font-weight: 500;
}

h1 {
  font-size: 30px;
}

h1 {
  font-family: 'Roboto', Helvetica, Arial, Lucida, sans-serif;
}

.et_pb_column_1_3 h1 {
  font-size: 26px;
}

.et_pb_text_23 h1 {
  font-weight: 700;
  font-size: 18px;
  color: #FFFFFF !important;
  text-align: center;
}

p {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  vertical-align: baseline;
  background: transparent;
}

p {
  padding-bottom: 1em;
}

p:not(.has-background):last-of-type {
  padding-bottom: 0;
}

span {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  vertical-align: baseline;
  background: transparent;
}

.et_pb_text_24 {
  line-height: 1.6em;
  font-size: 13px;
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.et_pb_text_24.et_pb_text {
  color: #FFFFFF !important;
}

.et_pb_text_25 {
  line-height: 1.6em;
  font-size: 13px;
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.et_pb_text_25.et_pb_text {
  color: #FFFFFF !important;
}

.et_pb_text_25 h1 {
  font-weight: 700;
  font-size: 18px;
  color: #FFFFFF !important;
  text-align: center;
}

.et_pb_text_26 h1 {
  font-weight: 700;
  font-size: 18px;
  color: #FFFFFF !important;
  text-align: center;
}

.et_pb_text_27 h1 {
  font-weight: 700;
  font-size: 18px;
  color: #FFFFFF !important;
  text-align: center;
}

.et_pb_text_28 h1 {
  font-weight: 700;
  font-size: 18px;
  color: #FFFFFF !important;
  text-align: center;
}

.et_pb_text_29 h1 {
  font-weight: 700;
  font-size: 18px;
  color: #FFFFFF !important;
  text-align: center;
}

.et_pb_text_30 h1 {
  font-weight: 700;
  font-size: 18px;
  color: #FFFFFF !important;
  text-align: center;
}

.et_pb_text_31 h1 {
  font-weight: 700;
  font-size: 18px;
  color: #FFFFFF !important;
  text-align: center;
}

img {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  vertical-align: baseline;
  background: transparent;
}

img {
  max-width: 100%;
  height: auto;
}

.et_pb_text_24 h1 {
  font-weight: 700;
  font-size: 18px;
  color: #FFFFFF !important;
  text-align: center;
}

code {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  vertical-align: baseline;
  background: transparent;
}

code {
  font-family: Courier New, monospace;
  margin-bottom: 10px;
}

.et_pb_with_background {
  position: relative;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}

.et_pb_section {
  position: relative;
  background-color: #fff;
  background-position: 50%;
  background-size: cover;
}

@media (min-width: 981px) {
  .et_pb_section {
      padding: 4% 0;
  }
}

@media only screen and (min-width: 1350px) {
  .et_pb_section {
      padding: 54px 0;
  }
}

.et_pb_row {
  width: 80%;
  max-width: 1080px;
  margin: auto;
  position: relative;
}

.et_pb_row {
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}

@media (min-width: 981px) {
  .et_pb_row {
      padding: 2% 0;
  }
}

@media only screen and (min-width: 1350px) {
  .et_pb_row {
      padding: 27px 0;
  }
}

.et_pb_row:after,
.et_pb_row_inner:after {
  content: "";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
  width: 0;
}

.et_pb_css_mix_blend_mode_passthrough {
  mix-blend-mode: unset !important;
}

.et_pb_column {
  float: left;
  background-size: cover;
  background-position: 50%;
  position: relative;
  z-index: 2;
  min-height: 1px;
}

.et_pb_column {
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}

@media (min-width: 981px) {
  .et_pb_column {
      margin-right: 5.5%;
  }
}

.et_pb_row .et_pb_column.et-last-child,
.et_pb_row .et_pb_column:last-child {
  margin-right: 0 !important;
}

@media (min-width: 981px) {

  .et_pb_row .et_pb_column.et-last-child,
  .et_pb_row .et_pb_column:last-child {
      margin-right: 0 !important;
  }
}

.et_pb_row_12.et_pb_row {
  padding-top: 50px;
  padding-bottom: 50px;
}

.et_pb_section_8 {
  min-height: 520px;
}

div.et_pb_section.et_pb_section_8 {
  background-image:  url(assets/images/join-babu-section-bg.webp) !important;
}

.et_pb_column_17 {
  padding-right: 0px;
  padding-left: 0px;
}

@media (min-width: 981px) {
  .et_pb_column_1_2 {
      width: 47.25%;
  }
}

.et_pb_column.et_pb_column_empty {
  min-height: 1px;
}

.et_pb_module {
  position: relative;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}

.et_pb_module {
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-duration: .2s;
  animation-duration: .2s;
}

.et_pb_text {
  word-wrap: break-word;
}

.et_pb_module.et_pb_text_align_left {
  text-align: left;
}

@media (min-width: 981px) {
  .et_pb_module {
      margin-bottom: 2.75%;
  }

  div.et_pb_row .et_pb_column .et_pb_module:last-child {
      margin-bottom: 0;
  }
}

.et_pb_column_16 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.et_pb_text_34 {
  line-height: 1.2em;
  font-family: 'Lato', Helvetica, Arial, Lucida, sans-serif;
  font-weight: 700;
  font-size: 30px;
  padding-top: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  margin-bottom: 16px !important;
  width: 99.9%;
}

.et_pb_text_34.et_pb_text {
  color: #FECF0A !important;
}

@media (min-width: 981px) {
  .et_pb_column_1_2 .et_pb_module {
      margin-bottom: 5.82%;
  }
}

.et_pb_button_module_wrapper.et_pb_button_alignment_left {
  text-align: left;
}

.et_pb_text_inner {
  position: relative;
}

.et_pb_text> :last-child {
  padding-bottom: 0;
}

a:where(:not(.wp-element-button)) {
  text-decoration: underline;
}

a {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  vertical-align: baseline;
  background: transparent;
}

a {
  color: #2ea3f2;
}

a {
  text-decoration: none;
}

.et_pb_button {
  font-size: 20px;
  font-weight: 500;
  padding: .3em 1em;
  line-height: 1.7em !important;
  background-color: transparent;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  border: 2px solid;
  border-radius: 3px;
  -webkit-transition-duration: .2s;
  transition-duration: .2s;
  -webkit-transition-property: all !important;
  transition-property: all !important;
}

.et_pb_button {
  position: relative;
}

.et_pb_button_module_wrapper>a {
  display: inline-block;
}


.et_pb_button:before {
  display: none;
}

#et-info-email:before,
#et-info-phone:before,
#et_search_icon:before,
.comment-reply-link:after,
.et-cart-info span::before,
.et-pb-arrow-next:before,
.et-pb-arrow-prev:before,
.et-social-icon a::before,
.et_audio_container .mejs-playpause-button button::before,
.et_audio_container .mejs-volume-button button::before,
.et_overlay:before,
.et_password_protected_form .et_submit_button::after,
.et_pb_button:after,
.et_pb_contact_reset:after,
.et_pb_contact_submit:after,
.et_pb_font_icon:before,
.et_pb_newsletter_button:after,
.et_pb_pricing_table_button:after,
.et_pb_promo_button:after,
.et_pb_testimonial:before,
.et_pb_toggle_title:before,
.form-submit .et_pb_button::after,
.mobile_menu_bar:before,
a.et_pb_more_button:after {
  font-family: ETmodules !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  -webkit-font-feature-settings: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 0 0;
  direction: ltr;
}

.et_pb_button_0,
.et_pb_button_0:after,
.et_pb_button_1,
.et_pb_button_1:after,
.et_pb_button_2,
.et_pb_button_2:after,
.et_pb_button_3,
.et_pb_button_3:after,
.et_pb_button_4,
.et_pb_button_4:after,
.et_pb_button_5,
.et_pb_button_5:after,
.et_pb_button_6,
.et_pb_button_6:after,
.et_pb_button_7,
.et_pb_button_7:after,
.et_pb_button_8,
.et_pb_button_8:after,
.et_pb_button_9,
.et_pb_button_9:after,
.et_pb_button_10,
.et_pb_button_10:after,
.et_pb_button_11,
.et_pb_button_11:after,
.et_pb_button_12,
.et_pb_button_12:after {
  transition: all 300ms ease 0ms;
}

.et_pb_button[data-icon]:not([data-icon=""]):after {
  content: attr(data-icon);
}

.et_pb_section .et_pb_button_1::after,
.et_pb_section .et_pb_button_2::after,
.et_pb_section .et_pb_button_3::after,
.et_pb_section .et_pb_button_4::after,
.et_pb_section .et_pb_button_5::after,
.et_pb_section .et_pb_button_6::after,
.et_pb_section .et_pb_button_7::after,
.et_pb_section .et_pb_button_8::after,
.et_pb_section .et_pb_button_9::after,
.et_pb_section .et_pb_button_10::after,
.et_pb_section .et_pb_button_11::after,
.et_pb_section .et_pb_button_12::after {
  line-height: inherit;
  font-size: inherit !important;
  margin-left: -1em;
  left: auto;
  font-family: ETmodules !important;
  font-weight: 400 !important;
}

a,
a:hover {
  text-decoration: none;
}

.et_pb_button:hover {
  background-color: hsla(0, 0%, 100%, .2);
}

.et_pb_button:hover,
.et_pb_module .et_pb_button:hover {
  border: 2px solid transparent;
  padding: .3em 2em .3em .7em;
}

.et_pb_bg_layout_light.et_pb_button:hover {
  background-color: #b50f58;
}

.et_pb_button_1_wrapper .et_pb_button_1,
.et_pb_button_1_wrapper .et_pb_button_1:hover {
  padding-top: 8px !important;
  padding-right: 32px !important;
  padding-bottom: 8px !important;
  padding-left: 32px !important;
}


@media (min-width: 981px) {
  .et_pb_module {
      margin-bottom: 9.27%;
  }
}

span {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  vertical-align: baseline;
  background: transparent;
}

h2 {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  vertical-align: baseline;
  background: transparent;
}

h2 {
  color: #333;
  padding-bottom: 10px;
  line-height: 1em;
  font-weight: 500;
}

h2 {
  font-size: 26px;
}

h2 {
  font-family: 'Roboto', Helvetica, Arial, Lucida, sans-serif;
}

.et_pb_text_0 h2 {
  font-family: 'Lato', Helvetica, Arial, Lucida, sans-serif;
  font-weight: 700;
  font-size: 40px;
  color: #FECF0A !important;
  text-align: center;
}

p:not(.has-background):last-of-type {
  padding-bottom: 0;
}

.et_pb_text_35 {
  line-height: 1.2em;
  font-family: 'Lato', Helvetica, Arial, Lucida, sans-serif;
  font-weight: 700;
  font-size: 30px;
  padding-top: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  margin-bottom: 32px !important;
  width: 99.9%;
}

.et_pb_text_35.et_pb_text {
  color: #FFFFFF !important;
}


/* These were inline style tags. Uses id+class to override almost everything */
#style-5EyYt.style-5EyYt {
  color: #FECF0A;
  font-size: 30px;
}

.et_pb_image_1 {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  margin-bottom: 16px !important;
  text-align: center;
}

.et_pb_image_0 {
  margin-bottom: 16px !important;
  text-align: center;
}

.et_pb_image_2 {
  padding-bottom: 1px;
  margin-bottom: 16px !important;
  text-align: center;
}

.et_pb_section .et_pb_button_0 {
  color: #000 !important;
  border-color: #FECF0A;
  border-radius: 10px;
  letter-spacing: 1px;
  font-family: 'Lato', Helvetica, Arial, Lucida, sans-serif !important;
  font-weight: 900 !important;
  background-color: #FECF0A;
}

.et_pb_button:after {
  display: none;
}

.et_pb_text_5 h2 {
  font-family: 'Lato', Helvetica, Arial, Lucida, sans-serif;
  font-weight: 900;
  font-size: 40px;
  color: #FECF0A !important;
  text-align: center;
}

.et_pb_section .et_pb_button_1,
.et_pb_section .et_pb_button_2,
.et_pb_section .et_pb_button_3,
.et_pb_section .et_pb_button_4,
.et_pb_section .et_pb_button_5,
.et_pb_section .et_pb_button_6,
.et_pb_section .et_pb_button_7,
.et_pb_section .et_pb_button_8,
.et_pb_section .et_pb_button_9,
.et_pb_section .et_pb_button_10 {
  color: #000 !important;
  border-color: #FECF0A;
  border-radius: 10px;
  letter-spacing: 2px;
  font-family: 'Lato', Helvetica, Arial, Lucida, sans-serif !important;
  font-weight: 700 !important;
  background-color: #FECF0A;
}

.et_pb_text_32 h2,
.et_pb_text_36 h2 {
  font-family: 'Lato', Helvetica, Arial, Lucida, sans-serif;
  font-weight: 900;
  font-size: 40px;
  color: #FECF0A !important;
  text-align: left;
}


.et_pb_text_33.et_pb_text {
  color: #FFFFFF !important;
}

.et_pb_section .et_pb_button_11 {
  color: #000 !important;
  border-color: #FECF0A;
  border-radius: 10px;
  font-family: 'Lato', Helvetica, Arial, Lucida, sans-serif !important;
  font-weight: 900 !important;
  background-color: #FECF0A;
}


@media only screen and (max-width: 767px) {

  .et_pb_text_0 h2,
  .et_pb_text_10 h1,
  .et_pb_text_10 h2,
  .et_pb_text_12 h1,
  .et_pb_text_12 h2 {
      font-size: 16px;
  }

  .et_pb_section {
      padding: 50px 0;
  }

  .et_pb_image_0 .et_pb_image_wrap img,
  .et_pb_image_1 .et_pb_image_wrap img,
  .et_pb_image_2 .et_pb_image_wrap img,
  .et_pb_image_3 .et_pb_image_wrap img,
  .et_pb_image_4 .et_pb_image_wrap img {
      width: 66px;
  }

  .et_pb_column {
      width: 100%;
      margin-bottom: 30px;
    
  }
  .et-cl-new{
    display: contents!important;
  }

  .et_pb_css_mix_blend_mode_passthrough {
      display: block;
  }

  .et_pb_section .et_pb_button_0 {
      font-size: 10px !important;
  }

  .et_pb_column .et_pb_module {
      margin-bottom: 30px;
  }

  .et_pb_text_5 h2 {
      font-size: 20px;
  }

  .et_pb_text_6 {
      font-size: 20px;
      margin-top: 8px !important;
      margin-bottom: 8px !important;
  }

  .et_pb_text_7 {
      font-size: 14px;
      max-width: 100%;
  }

  .et_pb_image_3 .et_pb_image_wrap img,
  .et_pb_image_4 .et_pb_image_wrap img {
      width: 100%;
  }

  .et_pb_text_10 {
      font-size: 16px;
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      margin-top: -31px !important;
      margin-bottom: 11px !important;
  }

  .et_pb_section .et_pb_button_1,
  .et_pb_section .et_pb_button_2,
  .et_pb_section .et_pb_button_3,
  .et_pb_section .et_pb_button_4,
  .et_pb_section .et_pb_button_5,
  .et_pb_section .et_pb_button_6,
  .et_pb_section .et_pb_button_7,
  .et_pb_section .et_pb_button_8,
  .et_pb_section .et_pb_button_9,
  .et_pb_section .et_pb_button_10 {
      font-size: 11px !important;
  }

  .et_pb_section {
      padding: 50px 0;
      background-position-x: 75%;
      background-size: cover;
  }

  .et_pb_text_32 h2,
  .et_pb_text_36 h2 {
      font-size: 20px;
      text-align: center;
  }

  .et_pb_text_11,
  .et_pb_text_13,
  .et_pb_text_34,
  .et_pb_text_38 {
      font-size: 14px;
      margin-bottom: 16px !important;
  }

  .et_pb_text_35,
  .et_pb_text_39 {
      font-size: 14px;
  }

  .et_pb_section .et_pb_button_11,
  .et_pb_section .et_pb_button_12 {
      font-size: 13px !important;
  }





  /* .et_pb_column_13 {
      overflow-x: scroll;
      display: grid;
      grid-template-columns: repeat(9, 70%);
  } */

  .et_pb_row_11.et_pb_row {
      padding-bottom: 0px;
      margin-left: auto !important;
      margin-right: auto !important;
      overflow-x: scroll;
      display: grid;
      grid-template-columns: repeat(9, 70%);
  }

  


}
.affiliate-page header .af-login{
  background: #FECF0A;
    color: #000;
    padding: 0px 12px;
    margin: 6px;
    height: 30px;
    display: inline-block;
    line-height: 30px;
    border-radius: 6px;
    text-decoration: none;
}
.affiliate-page .main-banner {
  height: 20vw;
  margin-top: 0;
  margin-bottom: 0;
  background: #000;
}
.affiliate-page .main-banner img{
 border-radius: 0;
}