@media(min-width:575px){
.download-bar{
    display: none;
}
    .desktop-log-reg{
        display: block;
    }
    .v-btn__content2 { 
        display: inline-block;
        color: #ffeb3b!important;      
        height: 38px;  
        padding: 0 16px;
        border-radius: 10px;
        background-color: #000!important;
        border-color: #000!important;
        font-weight: 700;
        width: 110px!important;
        min-height: 40px;
        text-align: center !important;
        min-width: 110px !important;
        margin-right: 24px!important; font-size: 16px; border: none;
    }
    .v-btn__content1 { 
        display: inline-block;
        color: rgba(0,0,0,.87);      
        height: 38px;  
        padding: 0 16px;
        border-radius: 10px;
        background-color: #fddd04!important;
        border-color: #fddd04!important;
        font-weight: 700;
        width: 155px!important;
        min-height: 40px;
        text-align: center !important;
        min-width: 155px!important;
        margin-right: 24px!important; font-size: 16px; border: none;
    }
    .login-reg-wrapper {
        height: 80px;     padding: 0 15px;
    }
  .login-header-new .bg-transparent {
    padding: 0px !important;
    display: none;
}
.login-header-new{
    padding: 0;
}
.desktop-menu{padding: 15px 0px;
    display: table;
    width: 100%;
    color: #000;
    height: auto;
    background-color: #000;
    text-align: left;}
.desktop-menu li   {
    letter-spacing: .36px!important; 
display: inline-block;
font-size: 18px;
padding-right: 8px!important;
padding-left: 8px!important;    
color: #fddd04;
text-transform: capitalize!important; 
font-size: 16px;
}
.desktop-menu li a
{
color: #fddd04;
font-size: 18px;     
padding-bottom: 5px;
font-weight: 500;
}

.desktop-menu li.active a{
color: #fff;
font-size: 18px; 
border-bottom: 3px solid #fff;
font-weight: 500;
}    

.marquee-notification.d-flex 
{
align-items: center;
background: #3a3a3a;
color: #fddd04;
font-size: 16px;
height: 40px;
line-height: 21px;
margin-top: -2px;
padding: 0 13px 0 5px;
margin: 0 !important;
max-width: 100% !important;
}
.footer-new{
    display: none;
}
.payment-sec {
    justify-content: space-around;
}
.payment-sec .payment-box {
    width: auto;
    padding: 6px 8px !important;
    text-align: left;
    display: flex !important;
    align-items: center !important;
    justify-content: start !important;
    flex-direction: column;
    background: transparent;
    min-width: 70px!important;
    /* justify-content: space-between; */
    height: auto;
    margin-right: 0;
    border-radius: 0;
    cursor: pointer;
}
.payment-sec .payment-box figure {
    margin: 0;
    display: none;
}
.payment-sec .payment-box span {
    font-size: 24px;
    color: #3a3a3a;
    font-weight: 600;
    text-align: left;
    width: 100%;
}
.home-tabing-slider .payment-sec .active {
    background: transparent !important;
    border-top: 3px solid #ffce01!important;
}
.main {
    max-width: 90rem;
    margin: auto;
}
.main-slider-inner-div ul {    
    gap: 0.99rem 0.99rem;
    justify-content: start;
    margin: auto;
}
.main-slider-inner-div ul li {
    width: 19%;
    height: 180px;
}
.main-slider-inner-div{
    padding: 0 25px;
}
.gT_cricket {
    min-width: 40vw;
}
    .gT_ld {
        width: 40vw;
        height: 250px!important;
    }
    .mobile-ankita{
        display: none;
    }
    .ankita_banner{
        padding:0 25px;
    }
    .desktop-ankita{
        display: block;
        border-radius: 20px;
        margin: 30px 0;
           
    }
    .d-m{
        display: none;
    }
    .d-s{
        display: block;
    }
    .common-slider{
        padding: 0 25px;
        margin: 0;
    }
    .common-slider h5{
        font-size: 18px;
    }
    .reg-data {
       
        max-width: 800px;
        margin: auto;
        
    }
    .registration-form .form-control, .login-form .form-control, .forgetpassword-buttn, s.submit-btn, .registration-form p, .login-form p,.registration-form p a, .login-form p a,.submit-btn {
      
        font-size: 21px;
       
    }
    .forgot-back-header {
       height: 30px;
    }
    .login-header-new, .header-font-size, .header-font-size span {
       
        color: #000!important; 
        
    }
    .header-outer{
        width: auto;
    }
    .deposite-d {
        width: 30px!important;
        height: 30px;
        min-width: 30px!important;
        min-height: 30px;
        border-radius: 50%;
        padding: 0;
        line-height: 29px;
        font-size: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 5px;
        margin: 0 10px !important;
    }
    .member-balance{
        display: none;
    }
    .v-btn__content3 {
        align-items: center;
        color: inherit;
        display: flex;
        flex: 1 0 auto;
        justify-content: inherit;
        line-height: normal;
        position: relative;
        transition: inherit;
        transition-property: opacity;
        height: 55px;
        cursor: pointer;
    }
    .v-btn__content3 img{
        width: 30px;
        margin-left: 15px;
    }
    .after-login{
        display: flex;
    }
    .width70{
        width: 70%;display: inline-block;
    }
    .width30{
        width: 26%;display: inline-block;
        margin-right: 2%;
        vertical-align: top;
    }
    .width30 .accordion-button{
        background-color: #f2f2f2;
    }
    .submit-btn{
        line-height: inherit!important;
    }
    .payment-select-group [type=radio]+label{
        font-size: 21px;
    }
    .inner-container{
        width: 100%;
        
    }
    .inner-sidebar-content{
        width: 80vw;
        margin: auto;
    }
}

@media(max-width:575px){
  
        .desktop-log-reg{
            display: none;
        }
        .desktop-menu{display: none;}
        .mobile-ankita{
            display: block;
        }
        .desktop-ankita{
            display: none;
        }

        .d-m{
            display: block;
        }
        .d-d{
            display: none;
        }
        .after-login{
            display: none!important;
        }
        .width30{
            display: none;
            
        }
        .width70{
            width: 100%;display: inline-block;
        }
    }

@media(max-width:575px)
{
    .inner-container{
        width: 100%;
    }
    .header {
        position: fixed !important;
    }

    .sports-team-table .back-lay-head h4 {
        font-size: 13px;
    }
    .sports-team-table .back-lay-head .half-table span {
        margin: 0px 4px;
    }
    .master-pass{
        font-size: 14px;
    }
    .closed-bet-slip .master-pass {
        font-size: 14px;
    }
    .market-depth-body li {
        padding: 10px 14px;
    }
    .fancy-sport-header button{
        font-size: 14px;
    }
    .bet-notification > div {
        max-width: 70px;
        padding-left: 10px;
    }
    .total-stack button {
        flex: 0 0 157px;
        max-width: 157px;
        font-size: 14px;
    }
    .form-stake .form-control {
        padding: 5px 6px;
    }
    .form-stake span {
        font-size: 13px;
        padding-left: 5px;
    }
    .form-stake h4 strong{
        font-size: 15px;
    }
    .accept-odds .form-check ,.form-stake h4{
        font-size: 14px;
    }
    .accept-odds span {
        font-size: 12px;
    }
    .total-stack h5 {
        font-size: 16px;
    }
    .total-stack h6 {
        font-size: 13px;
    }
    .wallet-form-control input.form-control {
        padding: 11px 12px;
        font-size: 15px;
    }
    .closed-bet-slip li strong{
        font-size: 14px;
    }
    .setting-panel .form-check input[type=checkbox] {
        font-size: 24px;
    }
    .mobile-height-layout{
        height: auto;
    }
    button.theme-btn {
        padding: 6px 29px;
        font-size: 20px;
    }
    .current-bets-table .odds-table th {
        padding-left: 18px;
        font-size: 14px;
    }
    .p2transfer-form .form-label {
        font-size: 16px;
    }
    .next-icon img {
        max-width: 19px;
    }
    .current-bets-table td {
        font-size: 14px;
    }
    .capital-inner span {
        font-size: 13px;
    }
    .search-games-sec {
        top: 145px;
        padding-top: 3px;
    }
    .order-checkbox .form-check{
        font-size: 14px;
    }
    .current-bets-table .lay-back td {
        padding: 12px 9px;
        font-weight: 700;
    }
    .active-log-table table th {
        font-size: 14px;
    }
    .active-log-table tr td {
        font-size: 14px;
    }
    .select-container.bet-history-dropdown .dropdown-item {
        padding-left: 85px;
    }
    .active-log-table tr td {
        font-size: 15px;
    }
    .show-hide-bet {
        padding: 2px 37px 8px;
    }
    .bet-name {
        padding: 4px 14px;
    }
    .bets-table thead th {
        font-size: 13px;
    }
    .main-casino-wrapper .games-card-inner:first-child {
        padding-top: 0px;
    }
    .search-games-sec .form-control {
        padding-bottom: 8px;;
    }
    .current-bets-table .master-pass::before {
        display: none;
        padding-left:0px;
    }
    .current-bets-table .master-pass{
           padding-left: 6px;
           margin-left: 0px;
           }
    .bottom-navigation ul li {
        padding: 6px 8px;
        font-size: 14px;
    }
    .bottom-navigation div {
        max-width: 31px;
    }
    .games-slot .slot1 {
        padding: 7px 4px;
    }
    .games-slot .slot1 img {
        max-width: 38px;
        margin: 0 auto;
    }
    .games-slot span{
        font-size: 14px;
    }
    .header .social-header a {
        padding: 6px 7px;
        margin-right: 7px;
        border-radius: 5px;
        font-size: 14px;
    }
    .games-wrapper .card {
        padding: 10px 16px;
        border-radius: 5px;
        margin-bottom: 7px;
        box-shadow: 0 1px 5px 0px rgba(0,0,0,.06);
    }
    .games-wrapper .card strong {
        font-size: 28px;
    }
    .games-wrapper .card span {
        font-size: 17px;
    }
    .leftpanel{
        display: none;
    }
    .main-outer{display: flex; justify-content: center; align-items: center;}
    .sidebar-wrapper {
        width: 288px;
    }
    .sidebar-wrapper ul a{
        font-size: 15px;
        background-position: right 11px top 17px;
        background-size: 20px;
    }
    .logout-button{
        font-size: 17px;
    }
    .time-zone{
        font-size: 15px;
    }
    .without-login {
        padding-top: 45px !important
    }
    .with-login{
        padding-top: 65px !important;
    }
    .casino-category img {
        max-width: 41px;
    }
    .casinofilter span {
        padding: 2px 10px;
        margin-right: 15px;
        font-size: 13px;
    }
    .subcasino span {
        padding: 4px 11px;
        margin-right: 7px;
        font-size: 14px;
    }
    .parlay-checkbox .form-check {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        padding-left: 0;
    }
    .bet-notification {
        left: 0px;
        bottom: 76px;
    }
    .by-time-sec .capital{
        position: relative;
    }
    .by-time-sec .in-play {
        padding: 5px 14px;
    }
    /* .capital button {
        position: absolute;
        right: 0;
        bottom: 0;
    } */
    .capital-inner {
        flex: 0 0 63%;
        max-width: 63%;
    }
    .parlay-checkbox {
        background: #1b1f23;
        padding: 8px  13px 8px 11px;
        margin-right: 9px;
    }
    .sports-heading {
        font-size: 16px;
    }
    .game-menu ul {
        overflow-x: auto;
    }
    /* .match-odd-table.bg-white.p-3.px-0.py-1 {
        width: 414px;
    } */
    .match-odd-table.mobile-match{
        width: 100%!important;
    }
    .sports-team-table {
        overflow-x: auto;
    }
    .game-menu ul::-webkit-scrollbar-thumb,.game-menu ul,::-webkit-scrollbar-track {
        /* background: #333333; */
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    border-radius: 10px;
      }

    .game-menu ul li svg {
        font-size: 21px;
        margin-right: 5px;
    }
    .game-menu ul li {
        margin-right: 8px;
        padding: 3px 6px;
        font-size: 14px;
    }
    .parlay-inner-tab {
        width: 100%;
        justify-content: space-between;
    }
    .game-menu {
        padding: 9px 8px;
    }
    .parlay-checkbox .form-check  .form-check-input{
        margin-left: 0px;
    }
    .parlay-checkbox .form-check-label {
        font-size: 15px;
        display: none;
    }
    .parlay-icons {
        display: flex;
        align-items: center;
    }
    .parlay-inner-tab li:last-child {
        margin: 0;
    }
    .casinofilter {
        padding: 3px 7px;
    }
    .casino-all-games .games-card-inner h2 {
        padding: 10px 34px 10px 15px;
        font-size: 16px;
        margin-left: -19px;
    }
    .parlay-icons svg {
        font-size: 21px;
        margin-right: 7px;
    }
    .download-apk img {
        max-width: 118px;
    }
    .parlay-inner-tab li {
        font-size: 16px;
        margin-right: 10px;
    }
    .match-list ul li {
        padding: 7px 26px 7px 12px;
        font-size: 15px;
    }
    /* .login-log-sec {
       position: initial;
    } */
    .login-panel main {
        padding-top: 0px;
    }
    .games-inner aside li {
        padding: 7px 10px;
    }
    main.showbottomnavigation {
        height: calc(100vh - 71px);
    }
    .bottom-navigation{
        position: fixed;
    }
    .sports-widget {
        position: fixed;
    }
    .market-depth-modal {
        min-height: auto;
    }
    .market-depth-modal-announcement{
        min-height: 100% !important;
    }
    .info-rule {
        padding-left: 31px;
    }
    .info-rule li {
        font-size: 15px;
        margin-bottom: 14px;
        padding: 0px 13px 0px 4px;
    }
    .info-detail .title h4{
        font-size: 17px;
    }
    .sport-rules-sec li{
        font-size: 17px;
    }
    .sport-rules-sec li button{
        font-size: 18px;
    }
    .select-container .dropdown-menu a {
        font-size: 15px;
        padding: 4px 12px;
    }
    .sport-listing .accordion-button {
        padding: 10px 17px 10px 0px;
    }
    .sports-widget ul {
        padding: 4px 9px;
    }
    .live-score{
        font-size: 17px;
    }
    .team-score span{
        font-size: 16px;
    }
    .sports-tab-panel h3.active::after {
        width: 49px;
    }
    .sports-listing-score .team-wise-score {
        padding: 3px 7px;
    }
    .sports-listing-score .team-wise-score strong {
        font-size: 16px;
    }
    .balance-sec {
        padding: 9px 10px;
        border-radius: 8px;
    }
    .balance-sec h5 {
        font-size: 17px;
    }
    .sports-tab-panel h3,.order-checkbox label.text-yellow {
        font-size: 15px;
    }
    .main-casino-wrapper .games-card-inner {
        padding: 10px 23px;
        margin: 0px -10px;
        border-radius: 0px;
    }
    .capital .counter {
        text-align: center;
        font-size: 13px;
    }
    .in-play-green {
        font-size: 11px;
    }
    .sport-listing .accordion-button{
        font-size: 14px;
    }
}

@media(max-width:380px)
{
    .games-wrapper .game-card {
        width: calc(33.33% - 6px);
        margin: 4px 3px;
    }
    .leading-normal {
        padding: 2px 8px;
    }
    .leading-normal div {
        font-size: 11px!important;
    }
    .sports-listing-score .team-wise-score span {
        font-size: 13px;
    }

    .in-play-green {
        font-size: 11px;
    }

    .games-inner aside li span {
        font-size: 12px;
    }

    .counter {
        font-size: 14px;
    }
    .sports-team-table li > div >div {
        padding: 4px 6px;
        font-size: 13px;
        width: 64px;
    }
    .sports-team-table .back-lay-head .half-table span {
        margin: 0px 6px;
        font-size: 11px;
    }
    .sports-team-table .overlay-match {
        width: 131px;
    }
    .sports-team-table .back-lay-head h4 {
        font-size: 13px;
    }
    .balance-label {
        font-size: 16px;
    }
    .market-depth-body .match-point{
        font-size: 14px;
    }
    .select-container .dropdown-toggle::after{
        background-size: 13px;
    }
    .select-container .dropdown-toggle{
        font-size: 15px;
    }
    .match-odd-table li h2{
        font-size: 14px;
    }
    .plays-bet form {
        margin-top: 0px;
    }
    .bet-numeric button {
        width: calc(25% - 4px);
    }
    .fix-bet-btn button {
        width: calc(25% - 4px);
        font-size: 15px;
        padding: 5px 4px;
    }
    .cross-bet img {
        max-width: 33px;
    }
    .cross-bet {
        height: 150px;
        width: 104px;
    }
 .logo-sec {
    max-width: 106px;
    flex: 0 0 106px;
}
.header.before-login {
    padding: 10px 6px;
}
.game-card {
    border-radius: 6px;
}
.games-inner aside li {
    padding: 7px 7px;
    border-radius: 9px;
    margin-bottom: 8px;
}
.games-wrapper {
    padding: 3px 1px 3px 0px;
}
.games-inner aside li svg {
    font-size: 27px;
}
.games-inner aside {
    max-width: 71px;
    flex: 0 0 85px;
    padding: 4px 6px;
    margin-right: 10px;
}
.games-card-inner h2 {
    font-size: 14px;
    border-left: 2px solid #000;
}
.games-inner {
    padding: 12px 8px;
}

}
